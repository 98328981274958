import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    VStack,
    useColorMode,
    useTheme,
    Divider
} from '@chakra-ui/react';

const FAQ = () => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    const faqSections = [
        {
            title: "Réservations et Voyages",
            questions: [
                {
                    question: "Comment fonctionne le générateur de voyages ?",
                    answer: "Notre générateur de voyages utilise un algorithme intelligent qui crée un itinéraire personnalisé en fonction de vos préférences, budget et dates de voyage. Remplissez simplement le formulaire, et nous vous proposerons un voyage sur mesure qui correspond parfaitement à vos attentes."
                },
                {
                    question: "Puis-je modifier mon itinéraire une fois généré ?",
                    answer: "Absolument ! Votre itinéraire reste entièrement flexible. Vous pouvez ajuster chaque aspect de votre voyage, des dates aux activités, à tout moment avant votre départ."
                },
                {
                    question: "Quel est le délai minimum pour réserver ?",
                    answer: "Bien que nous recommandions de réserver à l'avance pour avoir plus d'options, notre système peut générer des voyages jusqu'à 48h avant le départ, selon les disponibilités."
                }
            ]
        },
        {
            title: "Paiements et Tarifs",
            questions: [
                {
                    question: "Quels moyens de paiement acceptez-vous ?",
                    answer: "Nous acceptons la plupart des cartes bancaires (Visa, Mastercard, American Express), les virements bancaires, et PayPal. Tous les paiements sont sécurisés et cryptés."
                },
                {
                    question: "Y a-t-il des frais cachés ?",
                    answer: "Non. Nous pratiquons une politique de transparence totale. Tous les frais sont clairement indiqués avant la confirmation de votre réservation. Aucun surcoût ne sera appliqué après votre validation."
                }
            ]
        },
        {
            title: "Modifications et Annulations",
            questions: [
                {
                    question: "Quelle est votre politique d'annulation ?",
                    answer: "Notre politique d'annulation est flexible. Les annulations effectuées plus de 48h avant le départ sont intégralement remboursées. Des conditions spécifiques peuvent s'appliquer selon les prestataires et les destinations."
                },
                {
                    question: "Comment modifier ma réservation ?",
                    answer: "Vous pouvez modifier votre réservation directement depuis votre espace client ou contacter notre service client disponible 24/7. Les modifications sont gratuites si effectuées plus de 72h avant le départ."
                }
            ]
        }
    ];

    return (
        <Box
            minH="100vh"
            pt="120px"
            pb="100px"
            bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
        >
            {/* En-tête */}
            <Container maxW="4xl" mb={16}>
                <VStack spacing={6} textAlign="center">
                    <Heading
                        as="h1"
                        size="2xl"
                        color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
                    >
                        Foire Aux Questions
                    </Heading>
                    <Text
                        fontSize="lg"
                        color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}
                        maxW="2xl"
                    >
                        Trouvez toutes les réponses à vos questions concernant nos services de voyage personnalisé.
                    </Text>
                    <Divider width="100px" borderWidth="2px" borderColor={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg} />
                </VStack>
            </Container>

            {/* Sections FAQ */}
            <Container maxW="3xl">
                <VStack spacing={12} align="stretch">
                    {faqSections.map((section, idx) => (
                        <Box key={idx} color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}>
                            <Heading
                                as="h2"
                                size="lg"
                                mb={6}
                                color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                            >
                                {section.title}
                            </Heading>
                            <Accordion allowMultiple>
                                {section.questions.map((faq, index) => (
                                    <AccordionItem
                                        key={index}
                                        border="none"
                                        mb={4}
                                    >
                                        <AccordionButton
                                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.lightgrey}
                                            _hover={{
                                                bg: colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.middlegrey,
                                            }}
                                            borderRadius="md"
                                            p={4}
                                            shadow="md"
                                        >
                                            <Box flex="1" textAlign="left" fontWeight="500">
                                                {faq.question}
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel
                                            pb={4}
                                            pt={4}
                                            px={4}
                                            bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
                                            borderRadius="md"
                                            color={colorMode === 'dark' ? 'gray.300' : 'gray.600'}
                                            fontSize="md"
                                            lineHeight="1.7"
                                        >
                                            {faq.answer}
                                        </AccordionPanel>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </Box>
                    ))}
                </VStack>

                {/* Contact Section */}
                <Box
                    mt={16}
                    p={8}
                    borderRadius="lg"
                    bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white} textAlign="center"
                    shadow="lg"
                >
                    <Text fontSize="lg" mb={2} color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}>
                        Vous n'avez pas trouvé la réponse à votre question ?
                    </Text>
                    <Text
                        color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                        fontWeight="500"
                    >
                        Contactez notre équipe support à contact@verygoodtrip.com
                    </Text>
                </Box>
            </Container>
        </Box>
    );
};

export default FAQ;