import React, { useState } from 'react';
import {
    Box,
    Link,
    Button,
    Container,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Stack,
    Text,
    VStack,
    Icon,
    SimpleGrid,
    InputGroup,
    InputLeftElement,
    Select,
    useToast,
    useColorMode,
    useTheme
} from '@chakra-ui/react';
import {
    FaPhone,
    FaEnvelope,
    FaMapMarkerAlt,
    FaUser,
    FaClock
} from 'react-icons/fa';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const contactMethods = [
    {
        icon: FaPhone,
        title: "Téléphone",
        value: "+33 1 23 45 67 89",
        link: "tel:+33123456789",
        available: "Lun-Ven, 9h-19h"
    },
    {
        icon: FaEnvelope,
        title: "Email",
        value: "contact@verygoodtrip.com",
        link: "mailto:contact@verygoodtrip.com",
        available: "Réponse sous 48h"
    },
    {
        icon: FaMapMarkerAlt,
        title: "Adresse",
        value: "Lyon, France",
        available: "Siège social"
    }
];


export default function ContactPage() {
    const { colorMode } = useColorMode();
    const theme = useTheme();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        travelType: '',
        budget: '',
        travelDate: '',
        message: ''
    });

    const toast = useToast();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logique d'envoi du formulaire ici
        toast({
            title: 'Message envoyé !',
            description: "Nous vous répondrons dans les plus brefs délais.",
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
        });
        setFormData({
            name: '',
            email: '',
            phone: '',
            travelType: '',
            budget: '',
            travelDate: '',
            message: ''
        });
    };

    return (
        <Box
            as="section"
            minH="100vh"
            bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
            pt="120px"
            pb="100px"
        >
            <Container maxW="7xl">
                <VStack spacing={16}>
                    {/* Header Section */}
                    <VStack spacing={6} textAlign="center" maxW="3xl" mx="auto">
                        <Box
                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                            px={4}
                            py={2}
                            borderRadius="full"
                        >
                            Contactez-nous
                        </Box>
                        <Heading
                            as="h1"
                            fontSize={{ base: '3xl', md: '5xl' }}
                            fontWeight="bold"
                            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
                        >
                            Planifions Votre Prochain Voyage
                        </Heading>
                        <Text fontSize={{ base: 'lg', md: 'xl' }} color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}>
                            Notre équipe est là pour vous aider à créer le voyage de vos rêves
                        </Text>
                    </VStack>

                    {/* Méthodes de contact */}
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full" color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}>
                        {contactMethods.map((method, index) => (
                            <MotionBox
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.2 }}
                            >
                                <VStack
                                    bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white} p={8}
                                    borderRadius="xl"
                                    shadow="xl"
                                    spacing={4}
                                    height="full"
                                    _hover={{
                                        transform: 'translateY(-5px)',
                                        shadow: '2xl',
                                    }}
                                    transition="all 0.3s"
                                >
                                    <Box
                                        bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        p={3}
                                        borderRadius="full"
                                    >
                                        <Icon as={method.icon} w={6} h={6} color="white" />
                                    </Box>
                                    <Text fontWeight="bold" fontSize="xl">
                                        {method.title}
                                    </Text>
                                    {method.link ? (
                                        <Link
                                            href={method.link}
                                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        >
                                            {method.value}
                                        </Link>
                                    ) : (
                                        <Text>{method.value}</Text>
                                    )}
                                    <Text fontSize="sm">
                                        <Icon as={FaClock} mr={2} />
                                        {method.available}
                                    </Text>
                                </VStack>
                            </MotionBox>
                        ))}
                    </SimpleGrid>

                    {/* Formulaire de contact */}
                    <Box
                        bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                        color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
                        borderRadius="2xl"
                        shadow="xl"
                        overflow="hidden"
                        w="full"
                        maxW="4xl"
                        mx="auto"
                        p={8}
                    >
                        <VStack spacing={8} align="stretch">
                            <VStack spacing={4} align="start">
                                <Heading as="h2" fontSize="2xl" mx="auto"
                                >
                                    Formulaire de Contact
                                </Heading>
                            </VStack>

                            <form onSubmit={handleSubmit}>
                                <Stack spacing={6}>
                                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                                        {/* Nom */}
                                        <FormControl isRequired>
                                            <FormLabel>Nom complet</FormLabel>
                                            <InputGroup>
                                                <InputLeftElement>
                                                    <Icon as={FaUser} color="gray.500" />
                                                </InputLeftElement>
                                                <Input
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder="Votre nom"
                                                    bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white} />
                                            </InputGroup>
                                        </FormControl>

                                        {/* Email */}
                                        <FormControl isRequired>
                                            <FormLabel>Email</FormLabel>
                                            <InputGroup>
                                                <InputLeftElement>
                                                    <Icon as={FaEnvelope} color="gray.500" />
                                                </InputLeftElement>
                                                <Input
                                                    name="email"
                                                    type="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder="votre@email.com"
                                                    bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white} />
                                            </InputGroup>
                                        </FormControl>
                                    </SimpleGrid>

                                    {/* Téléphone */}
                                    <FormControl>
                                        <FormLabel>Téléphone</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement>
                                                <Icon as={FaPhone} color="gray.500" />
                                            </InputLeftElement>
                                            <Input
                                                name="phone"
                                                type="tel"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                placeholder="Votre numéro (optionnel)"
                                                bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white} />
                                        </InputGroup>
                                    </FormControl>

                                    {/* Sujet */}
                                    <FormControl isRequired>
                                        <FormLabel>Sujet</FormLabel>
                                        <Select
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            placeholder="Sélectionnez un sujet"
                                            bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}                                        >
                                            <option value="question">Question générale</option>
                                            <option value="reservation">Réservation</option>
                                            <option value="reclamation">Réclamation</option>
                                            <option value="autre">Autre</option>
                                        </Select>
                                    </FormControl>

                                    {/* Message */}
                                    <FormControl isRequired>
                                        <FormLabel>Message</FormLabel>
                                        <Textarea
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            placeholder="Votre message..."
                                            rows={6}
                                            bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white} />
                                    </FormControl>

                                    {/* Bouton Envoyer */}
                                    <Button
                                        type="submit"
                                        bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                                        size="lg"
                                        _hover={{
                                            transform: 'translateY(-2px)',
                                            boxShadow: 'lg',
                                            bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
                                            color: colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color,
                                        }}
                                        w="full"
                                    >
                                        Envoyer le message
                                    </Button>
                                </Stack>
                            </form>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}