import useLoadResult from '../../hooks/useLoadResult';
import { Grid, GridItem, Box, Flex, useDisclosure, useColorMode, useTheme } from '@chakra-ui/react';
import TravelResult from "../sections/TravelResult";
import Footer from '../common/Footer';
import ResultSidebar from '../common/ResultSidebar';
import Sidebar from '../common/Sidebar';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import HomeNavbar from '../common/HomeNavbar';

export default function TravelPrograms() {
  const { isOpen, onToggle } = useDisclosure();
  const { colorMode } = useColorMode();
  const theme = useTheme();
  const location = useLocation();
  const searchId = location.state?.searchId;

  useLoadResult(searchId);

  useEffect(() => {
    if (!searchId) {
      return;
    }
  }, [searchId]);

  return (
    <Grid
      templateAreas={`
        "header header"
        "resultSidebar main"
        "footer footer"
      `}
      gridTemplateRows={'auto 1fr 60px'}
      gridTemplateColumns={'1fr'}
      h="100vh"
      overflowX="hidden"
    >
      <GridItem area={'header'}>
        <HomeNavbar />
      </GridItem>

      <GridItem area={'resultSidebar'}>
        <ResultSidebar onSidebarToggle={onToggle} isSidebarOpen={isOpen} />
      </GridItem>


      <GridItem bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg} area={'main'} position="relative" display="flex">
        <Flex direction="column">
          <Box
            flex="1"
            bgImage="url('../images/Nouvelle-Zelande.jpg')"  // Choix de l'image de fond de TravelResult
            bgSize="cover"             // Pour couvrir toute la surface
            bgPosition="center"         // Pour centrer l'image
            bgRepeat="no-repeat"        // Pour éviter la répétition de l'image
            bgAttachment="fixed"        // Pour rendre l'image fixe lors du défilement
          >
            {/* Superposition semi-transparente pour Mode DARK */}
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              bg={colorMode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.1)'}
              zIndex="1"
            />
            {/* Fin Superposition semi-transparente pour Mode DARK */}
            <TravelResult />
          </Box>

        </Flex>

        {/* Sidebar */}
        {isOpen && (
          <Box
            position="absolute"
            top="48px"
            h="calc(100% - 48px)"
            left="0"
            width="200px"
            bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
            borderRight="1px solid"
            borderRightColor={theme.colors.light_bg.middlegrey}
            zIndex="3"
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '5px', // Largeur de la scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                background: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg, // Couleur de la thumb
                borderRadius: '5px',
              },
            }}
          >
            <Sidebar />
          </Box>
        )}
      </GridItem>

      <GridItem area={'footer'}>
        <Footer />
      </GridItem>
    </Grid>
  );
}
