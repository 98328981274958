import React from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  Text,
  Image,
  Stack,
  useColorMode,
  useTheme,
  Container,
  VStack,
  Link,
} from "@chakra-ui/react";
import { EmailIcon } from '@chakra-ui/icons';

function NewsletterSignup() {
  const { colorMode } = useColorMode();
  const theme = useTheme();

  return (
    <Box
      position="relative"
      bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
      color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
      pt={20}
      pb={{ base: 10, lg: 20 }}
      overflow="hidden"
    >
      {/* Background Design Element */}
      <Box
        position="absolute"
        top="-10%"
        left="-10%"
        width="50%"
        height="70%"
        borderRadius="full"
        bg={colorMode === 'dark'
          ? `rgba(${hexToRgb(theme.colors.dark_tertiary.bg)}, 0.1)`
          : `rgba(${hexToRgb(theme.colors.light_tertiary.bg)}, 0.1)`}
        filter="blur(60px)"
        zIndex="0"
      />

      <Container maxW="7xl" position="relative">
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: 10, lg: 20 }}
          align="center"
          justify="space-between"
        >

          {/* Right Image */}
          <Box
            order={{ base: 1, lg: -1 }}
            position="relative"
            w={{ base: "full", lg: "50%" }}
            maxW={{ base: "200px", lg: "400px" }}
            h={{ base: "200px", lg: "400px" }}
          >
            <Image
              src="./img/home/Travel.jpg"
              alt="Newsletter Illustration"
              borderRadius="full"
              objectFit="contain"
              filter={colorMode === 'dark' ? 'brightness(0.9)' : 'none'}
              transition="transform 2s ease-in-out"
              _hover={{ transform: 'rotate(360deg)' }}
            />
          </Box>


          {/* Left Content */}
          <VStack
            spacing={6}
            align={{ base: 'center', lg: 'start' }}
            textAlign={{ base: 'center', lg: 'left' }}
            maxW="600px"
          >
            <Box
              bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
              p={2}
              px={4}
              borderRadius="full"
              color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
              fontSize="sm"
              fontWeight="500"
              alignSelf={{ base: 'center', lg: 'flex-start' }}
            >
              Newsletter VGT
            </Box>

            <Heading
              as="h2"
              size="2xl"
              fontWeight="bold"
              lineHeight="shorter"
              letterSpacing="tight"
            >
              Restez inspiré pour vos prochains voyages
            </Heading>

            <Text fontSize="lg" color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>
              Recevez en avant-première nos meilleures offres, promotions exclusives et conseils de voyage personnalisés.
            </Text>

            <Flex
              as="form"
              direction={{ base: "column", md: "row" }}
              w="full"
              maxW="600px"
              spacing={3}
              gap={3}
            >
              <Box position="relative" flex="1">
                <Input
                  type="email"
                  placeholder="Votre adresse e-mail"
                  size="lg"
                  pl={12}
                  bg={colorMode === 'dark' ? 'whiteAlpha.100' : 'white'}
                  border="2px solid"
                  borderColor={colorMode === 'dark' ? 'whiteAlpha.200' : 'gray.200'}
                  _hover={{
                    borderColor: colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg,
                  }}
                  _focus={{
                    borderColor: colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg,
                    boxShadow: 'none',
                  }}
                />
                <EmailIcon
                  position="absolute"
                  left={4}
                  top="50%"
                  transform="translateY(-50%)"
                  color={colorMode === 'dark' ? 'whiteAlpha.500' : 'gray.400'}
                />
              </Box>
              <Button
                size="lg"
                bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                px={8}
                _hover={{
                  transform: 'translateY(-2px)',
                  boxShadow: 'lg',
                  bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
                  color: colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color,
                }}
                transition="all 0.2s"
              >
                S'abonner
              </Button>
            </Flex>

            <Text fontSize="sm" color={colorMode === 'dark' ? 'gray.400' : 'gray.500'} maxW="lg">
              En vous inscrivant, vous acceptez notre{' '}
              <Link
                href="/politique-de-confidentialite"
                color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                _hover={{
                  textDecoration: 'underline',
                  color: colorMode === 'dark'
                    ? theme.colors.dark_tertiary.bg
                    : theme.colors.light_tertiary.bg
                }}
              >
                Politique de confidentialité
              </Link>.
              Vous pouvez vous désinscrire à tout moment via le lien présent dans nos emails.
            </Text>
          </VStack>


        </Stack>
      </Container>
    </Box>
  );
}


// Fonction utilitaire pour convertir les couleurs hex en rgb
const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    : '0, 0, 0';
};

export default NewsletterSignup;
