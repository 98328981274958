import { useState } from 'react';
import {
  FormControl,
  Textarea,
  Box,
  Text,
  useColorMode,
  useTheme,
  Divider
} from '@chakra-ui/react';
import TravelInterestsButtons from './TravelInterestsButtons';

const TravelPreferenceForm = ({ formData, handleChange }) => {
  const { colorMode } = useColorMode();
  const theme = useTheme();

  const [selectedInterests, setSelectedInterests] = useState([]);

  const handleSelectInterest = (interest) => {
    const updatedInterests = selectedInterests.includes(interest)
      ? selectedInterests.filter(i => i !== interest)
      : [...selectedInterests, interest];
    setSelectedInterests(updatedInterests);
    handleChange('interests', updatedInterests);
  };

  return (
    <Box>
      <Box mb="12">
        <Text
          fontSize="2xl"
          fontWeight="600"
          textAlign="center"
          mb="3"
          color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
        >
          Personnalisez votre voyage
        </Text>
        <Text
          fontSize="md"
          textAlign="center"
          color={colorMode === 'dark' ? theme.colors.dark_font.white : theme.colors.light_font.black}
          opacity="0.8"
          mb="8"
        >
          Sélectionnez vos centres d'intérêt et décrivez vos préférences pour un voyage sur mesure
        </Text>

        <Divider
          borderColor={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.middlegrey}
        />
      </Box>

      <Box
        mb="12"
        pb="8"
        borderBottom="1px solid"
        borderColor={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.middlegrey}
      >
        <TravelInterestsButtons
          selectedInterests={selectedInterests}
          onSelectInterest={handleSelectInterest}
        />
      </Box>

      <FormControl id="preference">
        <Text
          fontSize="lg"
          fontWeight="500"
          mb="4"
          textAlign="center"
          color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
        >
          Partagez-nous vos envies de voyage
        </Text>

        <Box
          position="relative"
          borderRadius="xl"
          overflow="hidden"
        >
          <Textarea
            bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
            color={colorMode === 'dark' ? theme.colors.dark_font.white : theme.colors.light_font.black}
            placeholder="Je souhaite découvrir la gastronomie locale, me détendre sur la plage, explorer des sites historiques..."
            rows={6}
            resize="none"
            fontSize="md"
            p="6"
            border="2px solid"
            borderColor={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.middlegrey}
            _focus={{
              borderColor: colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg,
              boxShadow: 'none'
            }}
            _hover={{
              borderColor: colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg
            }}
            value={formData.preference || ''}
            onChange={(e) => handleChange('preference', e.target.value)}
          />
          <Box
            position="absolute"
            bottom="4"
            right="4"
            fontSize="sm"
            color={colorMode === 'dark' ? theme.colors.dark_font.white : theme.colors.light_font.black}
            opacity="0.5"
          >
            {formData.preference?.length || 0} / 500 caractères
          </Box>
        </Box>
      </FormControl>
    </Box>
  );
};

export default TravelPreferenceForm;
