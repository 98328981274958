import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid, GridItem, Box, useColorMode, useTheme } from '@chakra-ui/react';
import HomeNavbar from '../common/HomeNavbar';
import Footer from '../common/Footer';
import IntroMain from '../sections/MainIntroVGT';

export default function TravelHome() {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    return (
        <>
            <Helmet>
                {/* Titre et Meta description */}
                <title>Présentation de Very Good Trip | Votre générateur de voyages</title>
                <meta name="description" content="Présentation de Very Good Trip, votre générateur de voyages sur mesure. Profitez de notre formulaire interactif pour planifier un voyage qui correspond à vos envies." />
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="générateur de voyages, voyage sur mesure, voyages personnalisés, Very Good Trip, voyages interactifs, application de voyage" />
                <link rel="canonical" href="https://verygoodtrip.org/intro" />

                {/* Open Graph pour les réseaux sociaux */}
                <meta property="og:title" content="Présentation de Very Good Trip | Votre générateur de voyages" />
                <meta property="og:description" content="Présentation de Very Good Trip, votre générateur de voyages sur mesure. Profitez de notre formulaire interactif pour planifier un voyage qui correspond à vos envies." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://verygoodtrip.org/intro" />
                <meta property="og:image" content="https://verygoodtrip.org/images/logoVGT.jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Présentation de Very Good Trip | Votre générateur de voyages" />
                <meta name="twitter:description" content="Créez votre voyage sur mesure avec Very Good Trip grâce à notre formulaire interactif." />
                <meta name="twitter:image" content="https://verygoodtrip.org/images/logoVGT.jpg" />

                {/* Schema.org pour les rich snippets */}
                <script type="application/ld+json">
                    {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Présentation de Very Good Trip | Votre générateur de voyages",
            "url": "https://verygoodtrip.org/intro",
            "description": "Présentation de Very Good Trip, votre générateur de voyages sur mesure. Profitez de notre formulaire interactif pour planifier un voyage qui correspond à vos envies.",
            "publisher": {
              "@type": "Organization",
              "name": "Very Good Trip",
              "logo": "https://verygoodtrip.org/images/logoVGT.png"
            }
          }
          `}
                </script>
            </Helmet>

            {/* Contenu de la page */}
            <Grid
                templateAreas={
                    `"header header"
          "main main"
          "footer footer"`
                }
                gridTemplateRows={'auto auto 60px'}
                gridTemplateColumns='1fr'
                h='auto'
                color='blackAlpha.700'
                fontWeight='bold'
            >
                <GridItem area={'header'}>
                    <HomeNavbar />
                </GridItem>

                <GridItem
                    area={'main'}
                    bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.lightgrey}
                    overflowX="hidden"
                    overflowY="hidden"
                >
                    {/* Contenu principal */}
                    <Box position="relative">
                        <IntroMain />
                    </Box>
                </GridItem>

                <GridItem area={'footer'}>
                    <Footer />
                </GridItem>
            </Grid>
        </>
    );
};
