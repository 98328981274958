import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    UnorderedList,
    ListItem,
    useColorMode,
    useTheme,
    Icon,
} from "@chakra-ui/react";
import {
    LockIcon,
    EmailIcon,
    InfoIcon,
    StarIcon,
    InfoOutlineIcon,
    SettingsIcon,
    CopyIcon
} from "@chakra-ui/icons";

function PrivacyPolicy() {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    const sections = [
        {
            title: "Qui sommes-nous ?",
            icon: InfoIcon,
            content: (
                <Text>
                    Very Good Trip est votre générateur de voyages personnalisés. Notre site web est accessible à l'adresse : https://verygoodtrip.com. Nous nous engageons à protéger votre vie privée et à être transparents sur les données que nous collectons et leur utilisation.
                </Text>
            )
        },
        {
            title: "Collecte et Utilisation des Données",
            icon: EmailIcon,
            content: (
                <VStack align="stretch" spacing={4}>
                    <Text>Nous collectons les informations suivantes :</Text>
                    <UnorderedList spacing={2} pl={4}>
                        <ListItem>Adresse e-mail</ListItem>
                        <ListItem>Nom et prénom (si fournis)</ListItem>
                        <ListItem>Préférences de voyage</ListItem>
                        <ListItem>Historique des recherches sur notre plateforme</ListItem>
                        <ListItem>Adresse IP et agent utilisateur de votre navigateur</ListItem>
                        <ListItem>Données de connexion et d'utilisation du service</ListItem>
                    </UnorderedList>
                    <Text mt={4}>
                        Lors de l'upload d'images, nous vous conseillons d'éviter les fichiers contenant des données EXIF de coordonnées GPS, car ces informations peuvent être extraites par les visiteurs du site.
                    </Text>
                </VStack>
            )
        },
        {
            title: "Cookies et Contenus Embarqués",
            icon: LockIcon,
            content: (
                <VStack align="stretch" spacing={4}>
                    <Text fontWeight="500">Cookies :</Text>
                    <Text>
                        Notre site utilise des cookies pour améliorer votre expérience. Lors de votre interaction avec notre site, nous pouvons sauvegarder :
                    </Text>
                    <UnorderedList spacing={2} pl={4}>
                        <ListItem>Vos préférences de connexion</ListItem>
                        <ListItem>Votre nom et email si vous les fournissez</ListItem>
                        <ListItem>Des cookies de session temporaires</ListItem>
                    </UnorderedList>
                    <Text fontWeight="500" mt={4}>Contenus embarqués :</Text>
                    <Text>
                        Les articles de notre site peuvent inclure des contenus intégrés (vidéos, images, articles...). Ces contenus se comportent comme si vous visitiez leur site d'origine. Ces sites peuvent collecter des données sur vous, utiliser des cookies et suivre votre activité.
                    </Text>
                </VStack>
            )
        },
        {
            title: "Durée de Conservation",
            icon: SettingsIcon,
            content: (
                <VStack align="stretch" spacing={4}>
                    <Text>
                        Vos données personnelles sont conservées aussi longtemps que nécessaire pour les finalités pour lesquelles elles ont été collectées.
                    </Text>
                    <UnorderedList spacing={2} pl={4}>
                        <ListItem>Les données de profil sont conservées jusqu'à la suppression de votre compte</ListItem>
                        <ListItem>Les commentaires et leurs métadonnées sont conservés indéfiniment</ListItem>
                        <ListItem>Les cookies de session expirent après un an</ListItem>
                    </UnorderedList>
                </VStack>
            )
        },
        {
            title: "Vos Droits",
            icon: InfoOutlineIcon,
            content: (
                <VStack align="stretch" spacing={4}>
                    <Text>Conformément au RGPD, vous disposez des droits suivants :</Text>
                    <UnorderedList spacing={2} pl={4}>
                        <ListItem>Droit d'accès à vos données personnelles</ListItem>
                        <ListItem>Droit de rectification de vos données</ListItem>
                        <ListItem>Droit à l'effacement de vos données</ListItem>
                        <ListItem>Droit à la limitation du traitement</ListItem>
                        <ListItem>Droit à la portabilité de vos données</ListItem>
                        <ListItem>Droit d'opposition au traitement</ListItem>
                    </UnorderedList>
                    <Text mt={4}>
                        Vous pouvez demander un fichier contenant toutes vos données personnelles en notre possession, ainsi que leur suppression, sous réserve des obligations légales et administratives.
                    </Text>
                </VStack>
            )
        },
        {
            title: "Transmission des Données",
            icon: CopyIcon,
            content: (
                <VStack align="stretch" spacing={4}>
                    <Text>
                        Vos données peuvent être analysées par des services automatisés pour :
                    </Text>
                    <UnorderedList spacing={2} pl={4}>
                        <ListItem>La détection des commentaires indésirables</ListItem>
                        <ListItem>La sécurisation du site</ListItem>
                        <ListItem>L'amélioration de nos services</ListItem>
                    </UnorderedList>
                    <Text mt={4}>
                        Nous ne partageons vos données qu'avec les services nécessaires au fonctionnement du site et dans le respect strict de la réglementation en vigueur.
                    </Text>
                </VStack>
            )
        },
        {
            title: "Contact",
            icon: StarIcon,
            content: (
                <Text>
                    Pour toute question concernant cette politique de confidentialité ou pour exercer vos droits, vous pouvez nous contacter à : privacy@verygoodtrip.com
                </Text>
            )
        }
    ];

    return (
        <Box
            position="relative"
            minH="100vh"
            bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
            overflow="hidden"
        >
            {/* Background Decoration */}
            <Box
                position="absolute"
                top="-10%"
                right="-5%"
                width="600px"
                height="600px"
                borderRadius="full"
                bg={colorMode === 'dark'
                    ? `rgba(${hexToRgb(theme.colors.dark_tertiary.bg)}, 0.1)`
                    : `rgba(${hexToRgb(theme.colors.light_tertiary.bg)}, 0.1)`}
                filter="blur(60px)"
                zIndex="0"
            />

            <Container maxW="4xl" position="relative" pt={{ base: 120, lg: 170 }} pb={{ base: 50, lg: 100 }}>
                {/* Header Section */}
                <VStack spacing={6} align="center" mb={16}>
                    <Box
                        p={2}
                        px={4}
                        borderRadius="full"
                        bg={colorMode === 'dark'
                            ? theme.colors.dark_tertiary.bg
                            : theme.colors.light_tertiary.bg}
                        color={colorMode === 'dark'
                            ? theme.colors.dark_tertiary.color
                            : theme.colors.light_tertiary.color}
                        fontSize="sm"
                        fontWeight="500"
                    >
                        Sécurité & Confidentialité
                    </Box>
                    <Heading
                        as="h1"
                        size="2xl"
                        textAlign="center"
                        fontWeight="bold"
                    >
                        Politique de Confidentialité
                    </Heading>
                    <Text
                        fontSize="lg"
                        opacity={0.8}
                        textAlign="center"
                        maxW="2xl"
                    >
                        Nous accordons une importance capitale à la protection de vos données personnelles.
                        Découvrez comment nous les utilisons et les protégeons.
                    </Text>
                    <Text fontSize="sm" color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>
                        Dernière mise à jour : {new Date().toLocaleDateString()}
                    </Text>
                </VStack>

                {/* Main Content */}
                <VStack spacing={8} align="stretch">
                    {sections.map((section, index) => (
                        <Box
                            key={index}
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white} borderRadius="xl"
                            overflow="hidden"
                            boxShadow="md"
                        >
                            <Box
                                p={6}
                                display="flex"
                                alignItems="center"
                                gap={3}
                            >
                                <Icon
                                    as={section.icon}
                                    boxSize={5}
                                    color={colorMode === 'dark'
                                        ? theme.colors.dark_tertiary.bg
                                        : theme.colors.light_tertiary.bg}
                                />
                                <Heading as="h2" size="md">
                                    {section.title}
                                </Heading>
                            </Box>
                            <Box p={6}>
                                {section.content}
                            </Box>
                        </Box>
                    ))}
                </VStack>
            </Container>
        </Box>
    );
}

// Fonction utilitaire pour convertir les couleurs hex en rgb
const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
        : '0, 0, 0';
};

export default PrivacyPolicy;