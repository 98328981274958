import React from "react";
import {
  Box,
  Flex,
  Link,
  Text,
  IconButton,
  Stack,
  useDisclosure,
  Collapse,
  useColorMode,
  useTheme,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  SunIcon,
  MoonIcon,
  CloseIcon,
} from "@chakra-ui/icons";


function HomeNavbar() {
  const { isOpen, onToggle } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const theme = useTheme();

  return (
    <Box
      id="HomeNavbar"
      as="nav"
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="1000"
      bg={colorMode === "dark"
        ? `rgba(${hexToRgb(theme.colors.dark.bg)}, 0.95)`
        : `rgba(${hexToRgb(theme.colors.light.bg)}, 0.95)`}
      backdropFilter="blur(10px)"
      color={colorMode === "dark" ? theme.colors.dark.color : theme.colors.light.color}
      boxShadow="sm"
    >
      <Flex
        maxW="1400px"
        mx="auto"
        px={{ base: 0, md: 6 }}
        h="70px"
        align="center"
        justify="space-between"
      >
        {/* Logo avec rotation */}
        <Link
          href="/"
          _hover={{ textDecoration: "none" }}
          p={4}
          ml="4"
        >
          <Box
            display="inline-block"
            transition="transform 0.3s ease"
            _hover={{ transform: "scale(1.15)" }}
          >
            <Text
              as="span"
              fontSize="2xl"
              fontWeight="bold"
              fontFamily="sans-serif"
              display="inline-block"
            >
              Very Good
              <Text
                as="span"
                pl="2"
                color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
              >
                Trip
              </Text>
            </Text>
          </Box>
        </Link>

        {/* IconButton visible only on mobile */}
        <Flex alignItems="center" display={{ base: "flex", lg: "none" }}>
          <IconButton
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
            aria-label={"Toggle Color Mode"}
            variant="ghost"
            borderRadius="full"
            color={colorMode === "dark"
              ? theme.colors.dark_tertiary.bg
              : theme.colors.light_tertiary.bg}
            _hover={{
              bg: colorMode === "dark"
                ? theme.colors.dark_bg.darkgrey
                : theme.colors.light_bg.lightgrey
            }}
          />
          <IconButton
            aria-label="Toggle navigation"
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            onClick={onToggle}
            m={4}
            variant="ghost"
            borderRadius="full"
            color={colorMode === "dark"
              ? theme.colors.dark_tertiary.bg
              : theme.colors.light_tertiary.bg}
            _hover={{
              bg: colorMode === "dark"
                ? theme.colors.dark_bg.darkgrey
                : theme.colors.light_bg.lightgrey
            }}
          />
        </Flex>
        {/* Desktop Navigation */}
        <Flex
          display={{ base: "none", lg: "flex" }}
          align="center"
          gap={8}
        >
          {["Présentation", "Mes voyages"].map((item, index) => (
            <Link
              key={index}
              href={item === "Accueil" ? "/" : `/${item.toLowerCase().replace(" ", "-")}`}
              fontSize="lg"
              fontWeight="500"
              position="relative"
              transition="all 0.3s"
              _hover={{
                _after: {
                  width: "100%"
                },
                textDecoration: "none",
                color: colorMode === "dark"
                  ? theme.colors.dark_tertiary.bg
                  : theme.colors.light_tertiary.bg
              }}
              _after={{
                content: '""',
                position: "absolute",
                width: "0%",
                height: "2px",
                bottom: "-4px",
                left: "0",
                bg: colorMode === "dark"
                  ? theme.colors.dark_tertiary.bg
                  : theme.colors.light_tertiary.bg,
                transition: "all 0.3s"
              }}
            >
              {item}
            </Link>
          ))}

          <IconButton
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
            variant="ghost"
            borderRadius="full"
            color={colorMode === "dark"
              ? theme.colors.dark_tertiary.bg
              : theme.colors.light_tertiary.bg}
            _hover={{
              bg: colorMode === "dark"
                ? theme.colors.dark_bg.darkgrey
                : theme.colors.light_bg.lightgrey
            }}
          />
        </Flex>
      </Flex>

      {/* Mobile Menu */}
      <Collapse in={isOpen}>
        <Box
          bg={colorMode === "dark"
            ? theme.colors.dark_secondary.bg
            : theme.colors.light_secondary.bg}
          position="absolute"
          top="70px"
          left="0"
          right="0"
          px={4}
          py={6}
          boxShadow="xl"
          zIndex={2}
        >
          <Stack spacing={6}>
            {["Présentation", "Mes voyages"].map((item, index) => (
              <Link
                key={index}
                href={item === "Accueil" ? "/" : `/${item.toLowerCase().replace(" ", "-")}`}
                fontSize="lg"
                fontWeight="500"
                color={colorMode === "dark"
                  ? theme.colors.dark_secondary.color
                  : theme.colors.light_secondary.color}
                transition="all 0.3s"
                _hover={{
                  textDecoration: "none",
                  color: colorMode === "dark"
                    ? theme.colors.dark_tertiary.bg
                    : theme.colors.light_tertiary.bg,
                  transform: "translateX(10px)"
                }}
              >
                {item}
              </Link>
            ))}
          </Stack>
        </Box>
      </Collapse>
    </Box>
  );
}

// Fonction utilitaire pour convertir les couleurs hex en rgb
const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    : '0, 0, 0';
};

export default HomeNavbar;