import { Box, Text, IconButton, useColorMode, useTheme } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { FaPlane } from "react-icons/fa";

function ResultSidebar({ isSidebarOpen, onSidebarToggle }) {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    return (
        <Box position="relative">
            <Box
                display="flex"
                justifyContent="left"
                alignItems="center"
                position="absolute"
                top="70px"
                left="0"
                zIndex={4}
                w="200px"
                h="50px"
                bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
                color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
                borderRadius="0 0 5px 0"
                boxShadow="md"  // Optional: Add a shadow to enhance the visibility
            >
                <Text fontWeight="bold" ml={6}>
                    Mes voyages
                </Text>
                <IconButton
                    icon={isSidebarOpen ? <CloseIcon /> : <FaPlane />}
                    variant="outline"
                    onClick={onSidebarToggle}
                    aria-label="Toggle Sidebar"
                    ml={4}
                    bg={theme.colors.light_secondary.bg}
                    color={theme.colors.light_secondary.color}
                    _hover={{
                        bg: theme.colors.light_bg.middlegrey
                    }}
                    boxShadow="md"  // Optional: Add a shadow to enhance the visibility
                />
            </Box>
        </Box>
    );
}

export default ResultSidebar;
