import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    List,
    ListItem,
    VStack,
    HStack,
    Icon,
    useColorMode,
    useTheme,
} from '@chakra-ui/react';
import {
    FaBuilding,
    FaUser,
    FaEnvelope,
    FaServer,
    FaShieldAlt,
    FaFileContract,
    FaUserShield,
    FaCopyright,
    FaCookie,
    FaGavel,
} from 'react-icons/fa';

const LegalMentions = () => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    return (
        <Box
            minH="100vh"
            bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
            py="150px"
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}

        >
            <Container maxW="7xl">
                <VStack spacing={12} align="stretch">
                    {/* Header Section */}
                    <Box textAlign="center" mb={8}>
                        <Box
                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                            px={4}
                            py={2}
                            borderRadius="full"
                            display="inline-block"
                            mb={4}
                        >
                            Documentation Légale
                        </Box>
                        <Heading
                            fontSize={{ base: '3xl', md: '4xl' }}
                            fontWeight="bold"
                            mb={4}
                        >
                            Mentions Légales
                        </Heading>
                        <Text
                            fontSize={{ base: 'lg', md: 'xl' }}
                            maxW="3xl"
                            mx="auto"
                            opacity={0.8}
                        >
                            En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique
                        </Text>
                    </Box>

                    {/* Main Content */}
                    <VStack spacing={8} align="stretch">
                        {/* Section 1 - Présentation */}
                        <Box
                            p={8}
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                            borderRadius="xl"
                            boxShadow="xl"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon
                                    as={FaBuilding}
                                    w={6}
                                    h={6}
                                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                />
                                <Heading as="h2" fontSize="2xl">
                                    Présentation du site
                                </Heading>
                            </HStack>
                            <List spacing={4}>
                                <ListItem>
                                    <HStack align="start" spacing={4}>
                                        <Icon
                                            as={FaBuilding}
                                            mt={1}
                                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        />
                                        <Text>
                                            <strong>Propriétaire :</strong> VeryGoodTrip – Un projet réalisé par RockYourNet et Make-it en partenariat
                                        </Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack align="start" spacing={4}>
                                        <Icon
                                            as={FaUser}
                                            mt={1}
                                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        />
                                        <Text>
                                            <strong>Créateurs :</strong> RockYourNet et Make-it
                                        </Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack align="start" spacing={4}>
                                        <Icon
                                            as={FaEnvelope}
                                            mt={1}
                                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        />
                                        <Text>
                                            <strong>Contact :</strong> contact@verygoodtrip.com
                                        </Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack align="start" spacing={4}>
                                        <Icon
                                            as={FaServer}
                                            mt={1}
                                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        />
                                        <Text>
                                            <strong>Hébergeur :</strong> O2Switch – 222 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand, France
                                        </Text>
                                    </HStack>
                                </ListItem>
                            </List>
                        </Box>

                        {/* Section 2 - Conditions générales */}
                        <Box
                            p={8}
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                            borderRadius="xl"
                            boxShadow="xl"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon
                                    as={FaFileContract}
                                    w={6}
                                    h={6}
                                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                />
                                <Heading as="h2" fontSize="2xl">
                                    Conditions générales d'utilisation
                                </Heading>
                            </HStack>
                            <Text>
                                L'utilisation du site <strong>www.verygoodtrip.com</strong> implique l'acceptation pleine et entière des conditions générales d'utilisation décrites ci-après. Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment.
                            </Text>
                        </Box>

                        {/* Section 3 - Services */}
                        <Box
                            p={8}
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                            borderRadius="xl"
                            boxShadow="xl"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon
                                    as={FaServer}
                                    w={6}
                                    h={6}
                                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                />
                                <Heading as="h2" fontSize="2xl">
                                    Description des services fournis
                                </Heading>
                            </HStack>
                            <Text>
                                Le site <strong>www.verygoodtrip.com</strong> a pour objet de fournir des services de génération et de personnalisation de voyages. Very Good Trip s'efforce de fournir des informations aussi précises que possible mais ne saurait être tenu responsable des omissions, des inexactitudes et des carences dans la mise à jour.
                            </Text>
                        </Box>

                        {/* Section 4 - Propriété intellectuelle */}
                        <Box
                            p={8}
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                            borderRadius="xl"
                            boxShadow="xl"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon
                                    as={FaCopyright}
                                    w={6}
                                    h={6}
                                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                />
                                <Heading as="h2" fontSize="2xl">
                                    Propriété intellectuelle
                                </Heading>
                            </HStack>
                            <Text>
                                Very Good Trip est propriétaire des droits de propriété intellectuelle ou détient les droits d'usage sur tous les éléments accessibles sur le site. Toute reproduction, représentation, modification ou adaptation totale ou partielle du site est strictement interdite.
                            </Text>
                        </Box>

                        {/* Section 5 - Responsabilité */}
                        <Box
                            p={8}
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                            borderRadius="xl"
                            boxShadow="xl"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon
                                    as={FaShieldAlt}
                                    w={6}
                                    h={6}
                                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                />
                                <Heading as="h2" fontSize="2xl">
                                    Limitations de responsabilité
                                </Heading>
                            </HStack>
                            <Text>
                                Very Good Trip ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l'utilisateur, lors de l'accès au site. Very Good Trip décline toute responsabilité quant à l'utilisation qui pourrait être faite des informations et contenus présents sur le site.
                            </Text>
                        </Box>

                        {/* Section 6 - RGPD */}
                        <Box
                            p={8}
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                            borderRadius="xl"
                            boxShadow="xl"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon
                                    as={FaUserShield}
                                    w={6}
                                    h={6}
                                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                />
                                <Heading as="h2" fontSize="2xl">
                                    Protection des données personnelles
                                </Heading>
                            </HStack>
                            <Text>
                                Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez d'un droit d'accès, de rectification et de suppression de vos données. Very Good Trip s'engage à assurer le meilleur niveau de protection à vos données personnelles.
                            </Text>
                        </Box>

                        {/* Section 7 - Cookies */}
                        <Box
                            p={8}
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                            borderRadius="xl"
                            boxShadow="xl"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon
                                    as={FaCookie}
                                    w={6}
                                    h={6}
                                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                />
                                <Heading as="h2" fontSize="2xl">
                                    Liens hypertextes et cookies
                                </Heading>
                            </HStack>
                            <Text>
                                Le site Very Good Trip contient des liens hypertextes vers d'autres sites et des cookies nécessaires au bon fonctionnement du site. En navigant sur le site, vous les acceptez. Vous pouvez désactiver ces cookies depuis les paramètres de votre navigateur.
                            </Text>
                        </Box>

                        {/* Section 8 - Droit applicable */}
                        <Box
                            p={8}
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                            borderRadius="xl"
                            boxShadow="xl"
                        >
                            <HStack spacing={4} mb={6}>
                                <Icon
                                    as={FaGavel}
                                    w={6}
                                    h={6}
                                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                />
                                <Heading as="h2" fontSize="2xl">
                                    Droit applicable
                                </Heading>
                            </HStack>
                            <Text>
                                Les présentes mentions légales sont régies par le droit français. En cas de litige, les tribunaux français seront seuls compétents.
                            </Text>
                        </Box>
                    </VStack>
                </VStack>
            </Container>
        </Box>
    );
};

export default LegalMentions;