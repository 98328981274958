import React from 'react';
import {
  Box,
  Heading,
  Text,
  Divider,
  useColorMode,
  useTheme,
  Card,
  Image,
  Stack,
  CardBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel
} from '@chakra-ui/react';
import { useAppContext } from '../../context/AppContext';
import ModernLoader from '../common/ModernLoader';

const TravelResult = () => {
  const { colorMode } = useColorMode();
  const theme = useTheme();
  const { state } = useAppContext();

  const illustrationImage = {
    destination: "https://images.pexels.com/photos/1169922/pexels-photo-1169922.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    trajet: "https://images.pexels.com/photos/21014/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    programme: "https://images.pexels.com/photos/4247727/pexels-photo-4247727.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    informations: "https://images.pexels.com/photos/3851254/pexels-photo-3851254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
  }

  if (state.isLoading) {
    return (
      <Box
        position="relative"
        zIndex={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex="1"
        h="100vh"
        w="100vw"
        color={colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color}
        p={{ sm: '0', md: '4' }}>
        <ModernLoader />
      </Box>
    );
  }

  if (state.error) {
    return (
      <Box
        position="relative"
        zIndex={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex="1"
        h="100%"
        w="100vw"
        color={colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color}
        p={{ sm: '0', md: '4' }}>
        <Text fontSize={{ base: "xl", md: "3xl" }} textShadow="2px 2px 30px rgba(0, 0, 0, 0.5)">Erreur: {state.error}</Text>
      </Box>
    );
  }

  if (!state.currentResult) {
    return (
      <Box
        position="relative"
        zIndex={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex="1"
        h="100%"
        w="100vw"
        color={colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color}
        p={{ sm: '0', md: '4' }}>
        <Text fontSize={{ base: "xl", md: "3xl" }} textShadow="2px 2px 30px rgba(0, 0, 0, 0.5)">Pas de résultats disponibles.</Text>
      </Box>
    )
  }

  const { presentation, trajet, sejour, informations } = state.currentResult;

  return (
    <Box
      position="relative"
      zIndex={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex="1"
      h="100%"
      w="100vw"
      color={colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color}
      p={{ sm: '0', md: '20' }}>
      <Heading
        size="lg"
        pt={{ base: "150", md: "50" }}
        pb={5}
        mx="auto"
        color={colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color}
        textShadow="2px 2px 30px rgba(0, 0, 0, 0.6)"
      >Programmes de Voyages</Heading>
      <Divider mb={4} mx="auto" w="350px" h="2px" />
      <Box
        p={5}
        mb={{ base: "70", md: "50" }}
        display="flex"
        flexDirection="column"
        maxW="1400px"
        opacity="0.98"
      >
        <Heading
          as="h2"
          size="lg"
          mx="auto"
          mb={5}
          color={colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color}
          textShadow="2px 2px 30px rgba(0, 0, 0, 0.6)"
        >Votre Voyage </Heading>
        <Divider mb={5} mx="auto" w="200px" h="2px" />

        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow='hidden'
          variant='outline'
        >
          <Image
            objectFit='cover'
            maxW={{ base: '100%', sm: '200px' }}
            src={illustrationImage.destination}
            alt='Caffe Latte'
          />

          <Stack>
            <CardBody>
              <Heading size='md'>Votre destination</Heading>

              <Text py='2'>
                {presentation}

              </Text>
            </CardBody>
          </Stack>
        </Card>

        <Divider mb={5} />

        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow='hidden'
          variant='outline'
        >
          <Image
            objectFit='cover'
            maxW={{ base: '100%', sm: '200px' }}
            src={illustrationImage.trajet}
            alt='Caffe Latte'
          />

          <Stack>
            <CardBody>
              <Heading size='md'>Trajet</Heading>

              <Text py='2'>
                {trajet}
              </Text>
            </CardBody>
          </Stack>
        </Card>

        <Divider mb={5} />

        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow='hidden'
          variant='outline'
        >
          <Image
            objectFit='cover'
            maxW={{ base: '100%', md: '200px' }}
            maxH={{ base: '300px', md: '100%' }}
            src={illustrationImage.programme}
            alt='Caffe Latte'
          />

          <Stack>
            <CardBody>
              <Heading size='md'>Programme</Heading>
              <Divider mb={3} variant="None" />
              <Accordion defaultIndex={[0]} allowMultiple>
                {Object.values(sejour).map((jour, index) => (
                  <AccordionItem key={index}>
                    <h2>
                      <AccordionButton>
                        <Box as='span' flex='1' textAlign='left'>
                          {jour.jour}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Heading as="h4" size="md" mb={2}>{jour.activite1.nom}</Heading>
                      <Text mb={2}>{jour.activite1.description}</Text>
                      <Heading as="h4" size="md" mb={2}>{jour.activite2.nom}</Heading>
                      <Text mb={2}>{jour.activite2.description}</Text>
                    </AccordionPanel>
                  </AccordionItem>
                ))}

              </Accordion>
            </CardBody>
          </Stack>
        </Card>

        <Divider mb={5} />

        <Card
          direction={{ base: 'column', sm: 'row' }}
          overflow='hidden'
          variant='outline'
        >
          <Image
            objectFit='cover'
            maxW={{ base: '100%', sm: '200px' }}
            src={illustrationImage.informations}
            alt='Caffe Latte'
          />

          <Stack>
            <CardBody>
              <Heading size='md'>Informations</Heading>

              <Text py='2'>
                {informations}
              </Text>
            </CardBody>
          </Stack>
        </Card>

      </Box>
    </Box>
  );
};

export default TravelResult;
