import React from 'react';
import { Box, Image, Icon, useColorMode, useTheme } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const images = [
    require("../../imageComponents/SliderAccueil/îleParadisiaque0.webp"),
    require("../../imageComponents/SliderAccueil/îleParadisiaque1.webp"),
    require("../../imageComponents/SliderAccueil/îleParadisiaque2.webp")
];

function ImageSlider() {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    const titleReveal = keyframes`
        0% { transform: translate3d(0, 100%, 0); opacity: 0; }
        100% { transform: translate3d(0, 0, 0); opacity: 1; }
    `;

    const subtitleReveal = keyframes`
        0% { transform: translate3d(-100%, 0, 0); opacity: 0; }
        50% { transform: translate3d(-100%, 0, 0); opacity: 0; }
        100% { transform: translate3d(0, 0, 0); opacity: 1; }
    `;

    const floatAnimation = keyframes`
    0%, 100% { transform: translate(-50%, 0); }
    50% { transform: translate(-50%, -15px); }
`;

    return (
        <Box
            display="flex"
            justifyContent="center"
            width="100vw"
            height="100vh"
            position="relative"
            overflow="hidden"
            bg={colorMode === 'dark' ? 'black' : 'gray.100'}
        >
            <style>{`
                .slide {
                    position: absolute;
                    opacity: 0;
                    transition: opacity 1.5s ease-in-out, transform 6s ease-in-out;
                    transform: scale(1.1);
                }

                .slide.active {
                    opacity: 1;
                    transform: scale(1);
                }

                @keyframes slideShow {
                    0%, 33% { opacity: 1; transform: scale(1); }
                    35%, 98% { opacity: 0; transform: scale(1.1); }
                    100% { opacity: 1; transform: scale(1); }
                }

                .slide0 { animation: slideShow 18s infinite; }
                .slide1 { animation: slideShow 18s infinite 6s; }
                .slide2 { animation: slideShow 18s infinite 12s; }
            `}</style>

            {images.map((url, index) => (
                <Image
                    key={index}
                    src={url}
                    alt={`Slide ${index}`}
                    className={`slide slide${index}`}
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    filter={colorMode === 'dark'
                        ? 'brightness(0.7) saturate(1.2)'
                        : 'brightness(1) saturate(1.1)'}
                />
            ))}

            {/* Overlay gradient */}
            <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bgGradient={colorMode === 'dark'
                    ? 'linear(to-b, transparent 0%, rgba(0,0,0,0.4) 100%)'
                    : 'linear(to-b, transparent 0%, rgba(0,0,0,0.2) 100%)'}
                zIndex={1}
            />

            <Box
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                textAlign="center"
                zIndex={2}
                width="100%"
            >
                <Box
                    overflow="hidden"
                    mb={6}
                    p={2}
                >
                    <Box
                        fontSize={{ base: "3rem", md: "6rem", lg: "7rem" }}
                        fontWeight="bold"
                        lineHeight="1.2"
                        animation={`${titleReveal} 1.2s cubic-bezier(0.4, 0, 0.2, 1)`}
                    >
                        <Box
                            as="span"
                            color="white"
                            textShadow="0 4px 12px rgba(0,0,0,0.3)"
                            display="inline"
                        >
                            Very Good{" "}
                        </Box>
                        <Box
                            as="span"
                            color={colorMode === 'dark'
                                ? theme.colors.dark_tertiary.bg
                                : theme.colors.light_tertiary.bg}
                            textShadow="0 4px 12px rgba(0,0,0,0.2)"
                            display="inline"
                        >
                            Trip
                        </Box>
                    </Box>
                </Box>

                <Box
                    overflow="hidden"
                    position="relative"

                >
                    <Box
                        fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
                        color="white"
                        fontWeight="medium"
                        letterSpacing="wide"
                        animation={`${subtitleReveal} 1.5s cubic-bezier(0.4, 0, 0.2, 1)`}
                        textShadow="0 2px 8px rgba(0,0,0,0.3)"
                        maxW="800px"
                        mx="auto"
                        px={4}
                    >
                        Votre générateur de voyages personnalisés
                    </Box>
                </Box>
            </Box>

            <Box
                position="absolute"
                bottom="10%"
                left="50%"
                transform="translateX(-50%)"
                zIndex={2}
                cursor="pointer"
                transition="all 0.3s ease"
                animation={`${floatAnimation} 3s ease-in-out infinite`}
                onClick={() => document.getElementById('multistep-section').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                })}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    transition="0.3s"
                    _hover={{
                        transform: "scale(1.5)"
                    }}
                >
                    <Box
                        color="white"
                        fontSize="lg"
                        fontWeight="medium"
                        opacity="0.9"
                        position="relative"
                        top={6}
                    >
                        Découvrir
                    </Box>
                    <Icon
                        as={ChevronDownIcon}
                        boxSize={100}
                        color="white"
                        filter="drop-shadow(0 2px 4px rgba(0,0,0,0.2))"
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default ImageSlider;
