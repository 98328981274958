import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    VStack,
    HStack,
    Icon,
    Button,
    Badge,
    Image,
    Tag,
    useColorMode,
    useTheme,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import {
    FaClock,
    FaUserFriends,
    FaStar
} from 'react-icons/fa';

const Excursions = () => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    const excursions = [
        {
            title: "Trek en montagne",
            description: "Découvrez des panoramas à couper le souffle lors d'une randonnée guidée",
            image: "./img/home/treking.jpg",
            duration: "1 journée",
            difficulty: "Modérée",
            groupSize: "Max 8 personnes",
            price: "À partir de 75€",
            rating: 4.8,
            type: "Aventure"
        },
        {
            title: "Plongée en récif",
            description: "Explorez les fonds marins et leur extraordinaire biodiversité",
            image: "./img/home/plongee.jpg",
            duration: "3 heures",
            difficulty: "Tous niveaux",
            groupSize: "Max 4 personnes",
            price: "À partir de 90€",
            rating: 4.9,
            type: "Aquatique"
        },
        {
            title: "Safari photos",
            description: "Immortalisez la faune sauvage avec les conseils d'un photographe pro",
            image: "./img/home/safari.jpg",
            duration: "4 heures",
            difficulty: "Facile",
            groupSize: "Max 6 personnes",
            price: "À partir de 60€",
            rating: 4.7,
            type: "Nature"
        }
    ];

    return (
        <Box
            minH="100vh"
            bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
            pt="70px"
        >
            {/* Hero Section */}
            <Box
                bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                py={20}
                position="relative"
                overflow="hidden"
            >
                <Container maxW="7xl">
                    <VStack spacing={6} align="center" textAlign="center">
                        <Badge
                            colorScheme="pink"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm"
                        >
                            Excursions & Activités
                        </Badge>
                        <Heading size="3xl" maxW="800px">
                            Des expériences uniques à chaque destination
                        </Heading>
                        <Text fontSize="xl" maxW="2xl" opacity={0.8}>
                            Enrichissez votre voyage avec nos excursions soigneusement sélectionnées
                        </Text>
                    </VStack>
                </Container>
            </Box>

            {/* Types d'excursions */}
            <Container maxW="7xl" py={16}>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                    {excursions.map((excursion, index) => (
                        <Box
                            key={index}
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                            borderRadius="xl"
                            overflow="hidden"
                            boxShadow="xl"
                            transition="transform 0.3s"
                            _hover={{ transform: 'translateY(-5px)' }}
                        >
                            <Box position="relative">
                                <Image
                                    src={excursion.image}
                                    alt={excursion.title}
                                    w="full"
                                    h="200px"
                                    objectFit="cover"
                                />
                                <Tag
                                    position="absolute"
                                    top={4}
                                    right={4}
                                    bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                                >
                                    {excursion.type}
                                </Tag>
                            </Box>

                            <VStack p={6} spacing={4} align="stretch">
                                <Heading size="lg">{excursion.title}</Heading>
                                <Text opacity={0.8}>{excursion.description}</Text>

                                <HStack spacing={4}>
                                    <HStack>
                                        <Icon as={FaClock} />
                                        <Text fontSize="sm">{excursion.duration}</Text>
                                    </HStack>
                                    <HStack>
                                        <Icon as={FaUserFriends} />
                                        <Text fontSize="sm">{excursion.groupSize}</Text>
                                    </HStack>
                                </HStack>

                                <HStack justify="space-between" align="center">
                                    <Text
                                        fontWeight="bold"
                                        fontSize="lg"
                                        color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                    >
                                        {excursion.price}
                                    </Text>
                                    <HStack>
                                        <Icon as={FaStar} color="yellow.400" />
                                        <Text>{excursion.rating}</Text>
                                    </HStack>
                                </HStack>

                                <Button
                                    as={RouterLink}
                                    to="/"
                                    bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                                    _hover={{
                                        bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
                                        transform: 'translateY(-2px)',
                                        boxShadow: 'lg'
                                    }}
                                >
                                    Réserver maintenant
                                </Button>
                            </VStack>
                        </Box>
                    ))}
                </SimpleGrid>
            </Container>

            {/* CTA Section */}
            <Box
                pt={8}
                pb={20}
            >
                <Container maxW="3xl" textAlign="center"
                    bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white} p={20}
                    borderRadius="xl" shadow="lg">
                    <VStack spacing={6}>
                        <Heading>Besoin d'aide pour choisir ?</Heading>
                        <Text fontSize="lg">
                            Nos experts locaux sont là pour vous conseiller et vous aider à trouver
                            l'excursion parfaite pour votre séjour.
                        </Text>
                        <Button
                            as={RouterLink}
                            to="/"
                            size="lg"
                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                            _hover={{
                                bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg'
                            }}
                        >
                            Contacter un conseiller
                        </Button>
                    </VStack>
                </Container>
            </Box>
        </Box>
    );
};

export default Excursions;