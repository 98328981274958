import React from 'react';
import {
  Button,
  Wrap,
  WrapItem,
  useColorMode,
  useTheme,
  Box,
  Text
} from '@chakra-ui/react';

const travelInterests = [
  { label: 'Randonnée', icon: '🥾' },
  { label: 'Culture', icon: '🏛️' },
  { label: 'Plage', icon: '🏖️' },
  { label: 'Aventure', icon: '🚵' },
  { label: 'Gastronomie', icon: '🍽️' },
  { label: 'Histoire', icon: '📜' },
  { label: 'Nature', icon: '🌿' },
  { label: 'Relaxation', icon: '🧘' },
  { label: 'Sport', icon: '⚽' },
  { label: 'Shopping', icon: '🛍️' },
  { label: 'Vie Nocturne', icon: '🌃' },
  { label: 'Bien-être', icon: '💆' },
  { label: 'Photographie', icon: '📸' },
  { label: 'Exploration Urbaine', icon: '🚶' },
  { label: 'Art', icon: '🎨' },
  { label: 'Musique', icon: '🎵' },
  { label: 'Festival', icon: '🎉' },
  { label: 'Architecture', icon: '🏗️' },
  { label: 'Croisière', icon: '🚢' },
  { label: 'Parc à Thèmes', icon: '🎢' },
  { label: 'Observation de la Faune', icon: '🦁' },
  { label: 'Escalade', icon: '🧗' },
  { label: 'Sports Nautiques', icon: '🌊' },
  { label: 'Spa et Détente', icon: '🛀' },
  { label: 'Patrimoine Mondial', icon: '🏰' },
  { label: 'Activités en Famille', icon: '👨‍👩‍👧‍👦' },
  { label: 'Écotourisme', icon: '🌍' },
  { label: 'Road Trip', icon: '🚗' },
  { label: 'Équitation', icon: '🐎' },
  { label: 'Camps et Glamping', icon: '⛺' }
];

const TravelInterestsButtons = ({ selectedInterests, onSelectInterest }) => {
  const { colorMode } = useColorMode();
  const theme = useTheme();

  return (
    <Box>
      <Text
        textAlign="center"
        fontSize="lg"
        fontWeight="500"
        mb="6"
        color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
      >
        Sélectionnez vos centres d'intérêt
      </Text>
      <Wrap
        spacing={{ base: "2", md: "4" }}
        justify="center"
      >
        {travelInterests.map((interest, index) => (
          <WrapItem key={index}>
            <Button
              display="flex"
              flexDirection={{ base: 'column', md: 'row' }}
              alignItems="center"
              gap={{ base: "1", md: "2" }}
              py={{ base: "6", md: "6" }}
              px={{ base: "3", md: "4" }}
              borderRadius="xl"
              transition="all 0.3s ease"
              bg={
                selectedInterests.includes(interest.label)
                  ? colorMode === 'dark'
                    ? theme.colors.dark_tertiary.bg
                    : theme.colors.light_tertiary.bg
                  : colorMode === 'dark'
                    ? theme.colors.dark_bg.black
                    : theme.colors.light_bg.white
              }
              color={
                selectedInterests.includes(interest.label)
                  ? colorMode === 'dark'
                    ? theme.colors.dark_tertiary.color
                    : theme.colors.light_tertiary.color
                  : colorMode === 'dark'
                    ? theme.colors.dark_font.white
                    : theme.colors.light_font.black
              }
              border="2px solid"
              borderColor={
                selectedInterests.includes(interest.label)
                  ? colorMode === 'dark'
                    ? theme.colors.dark_tertiary.bg
                    : theme.colors.light_tertiary.bg
                  : 'transparent'
              }
              boxShadow={
                selectedInterests.includes(interest.label)
                  ? 'lg'
                  : 'lg'
              }
              _hover={{
                transform: 'translateY(-2px)',
                bg: colorMode === 'dark'
                  ? theme.colors.dark_tertiary.bg
                  : theme.colors.light_tertiary.bg,
                color: colorMode === 'dark'
                  ? theme.colors.dark_tertiary.color
                  : theme.colors.light_tertiary.color,
                boxShadow: 'lg'
              }}
              onClick={() => onSelectInterest(interest.label)}
            >
              <Text fontSize={{ base: "xl", md: "2xl" }}>
                {interest.icon}
              </Text>
              <Text
                fontSize={{ base: "xs", md: "sm" }}
                fontWeight="500"
              >
                {interest.label}
              </Text>
            </Button>
          </WrapItem>
        ))}
      </Wrap>
    </Box>
  );
};

export default TravelInterestsButtons;
