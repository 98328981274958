import React from 'react';
import { Box, Container, Heading, Text, VStack, Flex, Link, SimpleGrid, Icon, useColorMode, useTheme } from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

const CarteDuSite = () => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    const siteMap = [
        { title: 'Accueil', link: '/' },
        { title: 'Mes Voyages', link: '/mes-voyages' },
{/*         {
            title: 'Services', children: [
                { title: 'Voyages sur mesure', link: '/voyages-sur-mesure' },
                { title: 'Séjours hôtels', link: '/sejours-hotels' },
                { title: 'Excursions', link: '/excursions' },
                { title: 'Conseils voyage', link: '/conseils-voyage' },
            ]
        }*/},
        {
            title: 'Informations', children: [
                { title: 'Présentation', link: '/présentation' },
                { title: 'FAQ', link: '/faq' },
                { title: 'Contact', link: '/contact' },
            ]
        },
        {
            title: 'Légal', children: [
                { title: 'Politique de confidentialité', link: '/politique-de-confidentialite' },
                { title: 'Mentions légales', link: '/mentions-legales' },
            ]
        },
    {/* {
            title: 'Espace membre', children: [
                { title: 'Connexion', link: '/login' },
                { title: 'Inscription', link: '/signup' },
            ]
        },*/}
    ];

    return (
        <Box
            as="section"
            minH="100vh"
            bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
            py="150px"
        >
            <Container maxW="7xl">
                <Box textAlign="center" mb={16}>
                    <Box
                        bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                        color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                        px={4}
                        py={2}
                        borderRadius="full"
                        display="inline-block"
                        mb={4}
                    >
                        Navigation
                    </Box>
                    <Heading
                        size="2xl"
                        mb={4}
                    >
                        Carte du Site
                    </Heading>
                    <Text
                        fontSize="xl"
                        maxW="2xl"
                        mx="auto"
                        opacity={0.8}
                    >
                        Découvrez toutes les pages de notre site et naviguez facilement
                    </Text>
                </Box>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
                    {siteMap.map((section, index) => (
                        <Box
                            key={index}
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                            p={6}
                            borderRadius="xl"
                            boxShadow="xl"
                        >
                            {section.children ? (
                                <>
                                    <Heading size="lg" mb={4}>{section.title}</Heading>
                                    <VStack align="stretch" spacing={3}>
                                        {section.children.map((page, pageIndex) => (
                                            <Link
                                                key={pageIndex}
                                                as={RouterLink}
                                                to={page.link}
                                                p={3}
                                                borderRadius="md"
                                                transition="all 0.3s"
                                                _hover={{
                                                    bg: colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.lightgrey,
                                                    transform: 'translateX(8px)'
                                                }}
                                            >
                                                <Flex justify="space-between" align="center">
                                                    <Text>{page.title}</Text>
                                                    <Icon as={FaArrowRight}
                                                        color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                                    />
                                                </Flex>
                                            </Link>
                                        ))}
                                    </VStack>
                                </>
                            ) : (
                                <Link
                                    as={RouterLink}
                                    to={section.link}
                                    display="block"
                                >
                                    <Flex
                                        justify="space-between"
                                        align="center"
                                        p={3}
                                        transition="all 0.3s"
                                        _hover={{
                                            transform: 'translateX(8px)'
                                        }}
                                    >
                                        <Heading size="lg">{section.title}</Heading>
                                        <Icon
                                            as={FaArrowRight}
                                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        />
                                    </Flex>
                                </Link>
                            )}
                        </Box>
                    ))}
                </SimpleGrid>
            </Container>
        </Box>
    );
};

export default CarteDuSite;