'use client'
import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  useColorMode,
  useTheme,
  Heading,
  Text,
  Image,
} from '@chakra-ui/react'

const TravelBlogGenerator = () => {
  const [country, setCountry] = useState('')
  const [blogContent, setBlogContent] = useState(null)
  const [loading, setLoading] = useState(false)

  const { colorMode } = useColorMode()
  const theme = useTheme()

  const toast = useToast()

  const handleCountryChange = (e) => setCountry(e.target.value)

  const generateBlog = async () => {
    setLoading(true)
    try {
      const response = await fetch('http://localhost:8000/blog_generator', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ country }),
      })

      if (!response.ok) {
        throw new Error('Failed to generate blog')
      }

      const result = await response.json()
      setBlogContent(result.blogContent || {})

      toast({
        title: 'Blog generated successfully.',
        description: 'Your blog content is ready!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      console.error('Error generating blog:', error)
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box h="auto" py="40px" mt="40px">
      <Box
        borderWidth="1px"
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        rounded="lg"
        bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
        color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
        boxShadow="md"
        w="95%"
        maxWidth="1000px"
        p={{ base: "20px", md: "50px" }}
        m="10px auto"
        as="form"
      >
        <FormControl id="country" display="flex" flexDirection="column" mb={4}>
          <FormLabel fontSize="2xl" fontWeight="bold" mx="auto">
            Choisir une destination
          </FormLabel>
          <Input
            placeholder="Votre destination"
            value={country}
            onChange={handleCountryChange}
            maxWidth="300px"
            mx="auto"
            mt="10px"
            bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
            color={colorMode === 'dark' ? theme.colors.dark_bg.white : theme.colors.light_bg.black}
          />
        </FormControl>

        <Box w="100%" alignItems="center" display="flex" flexDirection="column" justifyContent="center">
          <Button
            mt="10px"
            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
            isLoading={loading}
            onClick={generateBlog}
            disabled={!country}
            _hover={{
              backgroundColor: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
              color: colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color,
            }}
          >
            Générer le Blog
          </Button>

          {blogContent && (
            <Box mt={6} maxW="100%">
              <Heading as="h2" size="md" my={9} textAlign="center">
                Blog sur {country.charAt(0).toUpperCase() + country.slice(1)}
              </Heading>

              {/* Introduction Section */}
              <Heading as="h3" size="lg" mt={9} mb={5}>
                Introduction
              </Heading>
              <Text fontSize="lg">{blogContent.introduction || "Aucune introduction disponible."}</Text>

              {/* Attractions Section */}
              <Heading as="h3" size="lg" mt={9} mb={5}>
                Attractions Touristiques
              </Heading>
              {blogContent.attractions?.length > 0 ? (
                blogContent.attractions.map((attraction, index) => (
                  <Box key={index} my="30px">
                    <Text fontSize="2xl" fontWeight="bold">{attraction.name}</Text>
                    <Text fontSize="lg">{attraction.description}</Text>
                    {attraction.image_url && (
                      <Image
                        src={attraction.image_url}
                        alt={attraction.name}
                        mx="auto"
                        mt="30px"
                        mb="50px"
                        maxW={{ base: "100%", md: "600px" }}  // `base` pour les écrans les plus petits, `sm` pour les écrans un peu plus grands
                        maxH={{ base: "100%", md: "500px" }}
                        objectFit="cover"
                      />
                    )}
                  </Box>
                ))
              ) : (
                <Text fontSize="lg">Aucune attraction trouvée.</Text>
              )}

              {/* Culture Section */}
              <Heading as="h3" size="lg" mt={9} mb={5}>
                Culture et Coutumes
              </Heading>
              <Text fontSize="lg">{blogContent.culture || "Aucune information disponible sur la culture."}</Text>

              {/* Gastronomy Section */}
              <Heading as="h3" size="lg" mt={9} mb={5}>
                Gastronomie Locale
              </Heading>
              <Text fontSize="lg">{blogContent.gastronomy || "Aucune information disponible sur la gastronomie."}</Text>

              {/* Favorite Moments Section */}
              <Heading as="h3" size="lg" mt={9} mb={5}>
                Mes Moments Préférés
              </Heading>
              <Text fontSize="lg">{blogContent.favorite_moments || "Aucun moment préféré mentionné."}</Text>

              {/* Travel Tips Section */}
              <Heading as="h3" size="lg" mt={9} mb={5}>
                Conseils de Voyage
              </Heading>
              <Text fontSize="lg">{blogContent.travel_tips || "Aucun conseil de voyage disponible."}</Text>

              {/* Conclusion Section */}
              <Heading as="h3" size="lg" mt={9} mb={5}>
                Conclusion
              </Heading>
              <Text fontSize="lg">{blogContent.conclusion || "Aucune conclusion disponible."}</Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default TravelBlogGenerator
