import {
  Box,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  useColorMode,
  useTheme,
  Icon
} from '@chakra-ui/react';
import { MdFlight, MdLocationOn } from 'react-icons/md';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';

const TravelDestinationAndDate = ({ formData, handleChange }) => {
  const { colorMode } = useColorMode();
  const theme = useTheme();

  const selectionRange = {
    startDate: formData.startDate || new Date(),
    endDate: formData.endDate || new Date(),
    key: 'selection',
  };

  const handleSelect = (ranges) => {
    const { selection } = ranges;
    handleChange('startDate', selection.startDate);
    handleChange('endDate', selection.endDate);
  };

  return (
    <Box>
      <Heading
        textAlign="center"
        fontSize="2xl"
        fontWeight="600"
        mb="8"
        color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
      >
        Planifiez votre prochaine aventure
      </Heading>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        gap="6"
        mb="8"
      >
        <FormControl>
          <FormLabel
            fontWeight="500"
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
          >
            Ville de départ
          </FormLabel>
          <InputGroup>
            <Input
              pl="12"
              bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
              color={colorMode === 'dark' ? theme.colors.dark_font.white : theme.colors.light_font.black}
              borderColor={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.middlegrey}
              _focus={{
                borderColor: colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg,
                boxShadow: 'none'
              }}
              placeholder="D'où partez-vous ?"
              value={formData.departure || ''}
              onChange={(e) => handleChange('departure', e.target.value)}
              height="12"
              fontSize="md"
            />
            <Box position="absolute" left="4" top="3" zIndex="1">
              <Icon
                as={MdFlight}
                color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                boxSize="6"
              />
            </Box>
          </InputGroup>
        </FormControl>

        <FormControl>
          <FormLabel
            fontWeight="500"
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
          >
            Destination
          </FormLabel>
          <InputGroup>
            <Input
              pl="12"
              bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white}
              color={colorMode === 'dark' ? theme.colors.dark_font.white : theme.colors.light_font.black}
              borderColor={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.middlegrey}
              _focus={{
                borderColor: colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg,
                boxShadow: 'none'
              }}
              placeholder="Où souhaitez-vous aller ?"
              value={formData.destination || ''}
              onChange={(e) => handleChange('destination', e.target.value)}
              height="12"
              fontSize="md"
            />
            <Box position="absolute" left="4" top="3" zIndex="1">
              <Icon
                as={MdLocationOn}
                color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                boxSize="6"
              />
            </Box>
          </InputGroup>
        </FormControl>
      </Flex>

      <FormControl>
        <FormLabel
          textAlign="center"
          fontWeight="500"
          mb="4"
          color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
        >
          Sélectionnez vos dates de voyage
        </FormLabel>
        <Box
          className="custom-date-range"
          display="flex"
          justifyContent="center"
        >
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelect}
            editableDateInputs={true}
            showSelectionPreview={false}
          />
        </Box>
      </FormControl>

      <style jsx global>{`
        .custom-date-range .rdrCalendarWrapper {
          background-color: ${colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white} !important;
          font-size: 15px !important;
          border-radius: 12px !important;
          border: 1px solid ${colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.middlegrey} !important;
        }
        
        .custom-date-range .rdrDateDisplayWrapper {
          background-color: ${colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.lightgrey} !important;
          border-radius: 12px 12px 0 0 !important;
        }

        .custom-date-range .rdrDateDisplay input {
          color: ${colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color} !important;
        }
        
        .custom-date-range .rdrDateDisplayItem input {
          background-color: ${colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.white} !important;
          border: none !important;
        }
        
        .custom-date-range .rdrDateDisplayItem {
          border: none !important;
        }

        .rdrDayNumber span {
          color: ${colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color};
        }

        .rdrMonthAndYearWrapper {
          background-color: ${colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.lightgrey} !important;
          padding-top: 10px !important;
          padding-bottom: 10px !important;
        }

        .rdrMonthAndYearPickers select {
          color: ${colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color} !important;
        }

        .rdrStartEdge, 
        .rdrEndEdge,
        .rdrInRange {
          background: ${colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg} !important;
        }

        .rdrMonthPicker select option {
        color: black !important;
        }
        
        .rdrYearPicker select option {
        color: black !important;
        }

        .rdrDayHovered {
          border: none !important;
        }

        .rdrDayToday .rdrDayNumber span:after {
          background: ${colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg} !important;
        }

        .rdrDefinedRangesWrapper {
          display: none;
        }

        @media (max-width: 480px) {
          .custom-date-range .rdrCalendarWrapper {
            font-size: 12px !important;
          }
          .rdrDateDisplayItem {
            padding: 5px !important;
          }
        }
      `}</style>
    </Box>
  );
};

export default TravelDestinationAndDate;