import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    SimpleGrid,
    Icon,
    Button,
    Badge,
    List,
    ListItem,
    ListIcon,
    useColorMode,
    useTheme,
    Image
} from '@chakra-ui/react';
import {
    FaRoute,
    FaHotel,
    FaPlane,
    FaUmbrella,
    FaCheckCircle,
    FaMapMarkedAlt,
    FaUserClock,
    FaHandshake
} from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';


const MainVoyagesSurMesure = () => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    const features = [
        {
            icon: FaMapMarkedAlt,
            title: "Destinations Uniques",
            description: "Des itinéraires créés sur mesure selon vos envies et votre style de voyage"
        },
        {
            icon: FaUserClock,
            title: "Service Personnalisé",
            description: "Un conseiller dédié vous accompagne à chaque étape de votre projet"
        },
        {
            icon: FaHandshake,
            title: "Expertise Locale",
            description: "Des partenaires locaux sélectionnés pour leur fiabilité et leur qualité"
        }
    ];

    const benefits = [
        "Itinéraires 100% personnalisables",
        "Hébergements soigneusement sélectionnés",
        "Transport optimisé selon vos préférences",
        "Activités adaptées à vos centres d'intérêt",
        "Assistance 24/7 pendant votre voyage",
        "Conseils d'experts locaux"
    ];

    return (
        <Box
            minH="100vh"
            bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
            pt="70px"
        >
            {/* Hero Section */}
            <Box
                bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                py={20}
                position="relative"
                overflow="hidden"
            >
                <Container maxW="7xl">
                    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10} alignItems="center">
                        <VStack align="flex-start" spacing={6}>
                            <Badge
                                colorScheme="pink"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm"
                            >
                                Voyage Sur Mesure
                            </Badge>
                            <Heading size="3xl" lineHeight="shorter">
                                Créez le voyage de vos rêves
                            </Heading>
                            <Text fontSize="xl" opacity={0.8}>
                                Laissez-nous transformer vos envies de voyage en une expérience unique et inoubliable.
                            </Text>
                            <Button
                                as={RouterLink}
                                to="/"
                                size="lg"
                                bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                                _hover={{
                                    bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg'
                                }}
                            >
                                Commencer mon voyage
                            </Button>
                        </VStack>
                        <Box display={{ base: "none", lg: "block" }}
                            position="relative"
                            w={{ base: "full", lg: "100%" }}
                            maxW="400px"
                            h="400px">
                            <Image
                                src="./img/home/Travel.jpg"
                                alt="Voyage sur mesure"
                                borderRadius="xl"
                                boxShadow="2xl"
                            />
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Features Section */}
            <Container maxW="7xl" py={20}>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                    {features.map((feature, index) => (
                        <VStack
                            key={index}
                            align="flex-start"
                            p={8}
                            borderRadius="xl"
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                            boxShadow="xl"
                            transition="transform 0.3s"
                            _hover={{ transform: 'translateY(-5px)' }}
                        >
                            <Box
                                p={4}
                                borderRadius="lg"
                                bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                            >
                                <Icon as={feature.icon} w={6} h={6} />
                            </Box>
                            <Heading size="md">{feature.title}</Heading>
                            <Text>{feature.description}</Text>
                        </VStack>
                    ))}
                </SimpleGrid>
            </Container>

            {/* Process Section */}
            <Box
                bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                py={20}>
                <Container maxW="7xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Heading>Comment ça marche ?</Heading>
                            <Text fontSize="lg" maxW="2xl">
                                Un processus simple et efficace pour créer votre voyage parfait
                            </Text>
                        </VStack>

                        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={8} w="full">
                            {[
                                { icon: FaRoute, title: "Partagez vos envies", text: "Remplissez notre formulaire détaillé" },
                                { icon: FaPlane, title: "Proposition sur mesure", text: "Recevez votre itinéraire personnalisé" },
                                { icon: FaHotel, title: "Affinage", text: "Affinez les détails selon vos préférences" },
                                { icon: FaUmbrella, title: "Réservation", text: "Confirmez et préparez vos bagages" }
                            ].map((step, index) => (
                                <VStack key={index} spacing={4} textAlign="center">
                                    <Box
                                        p={4}
                                        borderRadius="full"
                                        bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                                    >
                                        <Icon as={step.icon} w={8} h={8} />
                                    </Box>
                                    <Heading size="md">{step.title}</Heading>
                                    <Text>{step.text}</Text>
                                </VStack>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>

            {/* Benefits Section */}
            <Container maxW="7xl" py={20}>
                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10} alignItems="center">
                    <Box>
                        <VStack align="flex-start" spacing={6}>
                            <Heading>Les avantages d'un voyage sur mesure</Heading>
                            <List spacing={4}>
                                {benefits.map((benefit, index) => (
                                    <ListItem key={index} display="flex" alignItems="center">
                                        <ListIcon
                                            as={FaCheckCircle}
                                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        />
                                        {benefit}
                                    </ListItem>
                                ))}
                            </List>
                            <Button
                                as={RouterLink}
                                to="/"
                                size="lg"
                                bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                                _hover={{
                                    bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'lg'
                                }}
                            >
                                Créer mon voyage
                            </Button>
                        </VStack>
                    </Box>
                    <Box display={{ base: "none", lg: "block" }}
                        position="relative"
                        w={{ base: "full", lg: "100%" }}
                        maxW="500px"
                        h="400px">
                        <Image
                            src="./img/home/home-travel-detoure.png"
                            alt="Voyage sur mesure"
                        />
                    </Box>
                </SimpleGrid>
            </Container>
        </Box>
    );
};

export default MainVoyagesSurMesure;