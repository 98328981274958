import React from 'react';
import {
    Box,
    Text,
    Heading,
    VStack,
    Button,
    useColorMode,
    useTheme,
    Container,
    SimpleGrid,
    Icon,
    Badge,
    HStack,
    Divider,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
    //FaPlane,
    FaMapMarkedAlt,
    FaClock,
    FaLightbulb,
    FaRobot,
    FaGlobe,
    FaUserFriends,
    FaLeaf,
    FaSuitcase,
    FaCalendarAlt
} from 'react-icons/fa';

const FeatureCard = ({ icon, title, description }) => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    return (
        <Box
            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
            p={6}
            borderRadius="xl"
            boxShadow="xl"
            transition="transform 0.3s"
            _hover={{ transform: 'translateY(-5px)' }}
        >
            <VStack align="start" spacing={4}>
                <Icon
                    as={icon}
                    w={6}
                    h={6}
                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                />
                <Heading size="md">{title}</Heading>
                <Text>{description}</Text>
            </VStack>
        </Box>
    );
};

const IntroMain = () => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    return (
        <Box
            minH="100vh"
            bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
        >
            <Container maxW="7xl"
                minH="100vh"
                bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
                color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
                pt="150px"
                pb="120px">
                <VStack spacing={16}>
                    {/* Hero Section */}
                    <VStack spacing={6} textAlign="center" maxW="3xl">
                        <Badge
                            colorScheme="pink"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm"
                        >
                            Découvrez l'avenir du voyage personnalisé
                        </Badge>
                        <Heading size="2xl">
                            Very Good Trip : Votre Générateur de Voyages Intelligent
                        </Heading>
                        <Text fontSize="xl" opacity={0.8} maxW="2xl">
                            Dans un monde où les voyages personnalisés sont devenus la norme, Very Good Trip révolutionne la façon dont vous planifiez vos aventures. Grâce à notre technologie de pointe, créez des expériences de voyage uniques et parfaitement adaptées à vos envies.
                        </Text>
                        <Button
                            as={Link}
                            to="/"
                            size="lg"
                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                            _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'xl',
                            }}
                            px={8}
                            h={14}
                        >
                            Commencer votre voyage
                        </Button>
                    </VStack>

                    {/* Fonctionnalités principales */}
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
                        <FeatureCard
                            icon={FaMapMarkedAlt}
                            title="Itinéraires Sur Mesure"
                            description="Un programme unique créé selon vos préférences, votre budget et votre style de voyage."
                        />
                        <FeatureCard
                            icon={FaRobot}
                            title="Intelligence Artificielle"
                            description="Notre algorithme avancé analyse vos préférences pour créer le voyage parfait."
                        />
                        <FeatureCard
                            icon={FaLightbulb}
                            title="Suggestions Personnalisées"
                            description="Découvrez des activités et des lieux qui correspondent exactement à vos centres d'intérêt."
                        />
                    </SimpleGrid>

                    {/* Section Présentation Détaillée */}
                    <Box
                        bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                        p={10}
                        borderRadius="2xl"
                        boxShadow="xl"
                        w="full"
                    >
                        <VStack spacing={8} align="start">
                            <Heading size="xl" color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}>
                                Qu'est-ce que Very Good Trip ?
                            </Heading>
                            <Text fontSize="lg">
                                Very Good Trip est une plateforme innovante qui utilise l'intelligence artificielle pour générer des itinéraires de voyage sur mesure. Contrairement aux agences de voyages traditionnelles ou aux sites de réservation classiques, notre plateforme offre une approche entièrement personnalisée, en prenant en compte vos préférences personnelles pour créer un programme qui vous ressemble.
                            </Text>

                            {/* Process en 3 étapes */}
                            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full" pt={8}>
                                {[
                                    {
                                        icon: FaCalendarAlt,
                                        title: "1. Planification",
                                        description: "Choisissez vos dates et votre destination idéale."
                                    },
                                    {
                                        icon: FaSuitcase,
                                        title: "2. Personnalisation",
                                        description: "Indiquez vos préférences et votre style de voyage."
                                    },
                                    {
                                        icon: FaGlobe,
                                        title: "3. Exploration",
                                        description: "Recevez votre itinéraire personnalisé et partez à l'aventure !"
                                    }
                                ].map((step, index) => (
                                    <VStack
                                        key={index}
                                        spacing={4}
                                        align="start"
                                        p={6}
                                        bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.lightgrey}
                                        borderRadius="xl"
                                    >
                                        <Box
                                            p={3}
                                            borderRadius="full"
                                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        >
                                            <Icon
                                                as={step.icon}
                                                color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                                                w={6}
                                                h={6}
                                            />
                                        </Box>
                                        <Heading size="md">{step.title}</Heading>
                                        <Text>{step.description}</Text>
                                    </VStack>
                                ))}
                            </SimpleGrid>
                        </VStack>
                    </Box>

                    {/* Section Avantages */}
                    <Box
                        bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                        p={10}
                        borderRadius="2xl"
                        boxShadow="xl"
                        w="full"
                    >
                        <VStack spacing={8}>
                            <Heading size="xl" color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}>
                                Les avantages de Very Good Trip
                            </Heading>
                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} w="full">
                                {[
                                    {
                                        title: "Personnalisation totale",
                                        description: "Chaque aspect de votre voyage est adapté à vos préférences personnelles.",
                                        icon: FaUserFriends
                                    },
                                    {
                                        title: "Gain de temps précieux",
                                        description: "Obtenez votre itinéraire complet en quelques minutes seulement.",
                                        icon: FaClock
                                    },
                                    {
                                        title: "Voyage responsable",
                                        description: "Des suggestions respectueuses de l'environnement et des cultures locales.",
                                        icon: FaLeaf
                                    },
                                    {
                                        title: "Flexibilité maximale",
                                        description: "Modifiez votre itinéraire à tout moment selon vos envies.",
                                        icon: FaLightbulb
                                    }
                                ].map((advantage, index) => (
                                    <HStack
                                        key={index}
                                        align="start"
                                        spacing={4}
                                        p={6}
                                        bg={colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.lightgrey}
                                        borderRadius="xl"
                                    >
                                        <Box
                                            p={3}
                                            borderRadius="full"
                                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        >
                                            <Icon
                                                as={advantage.icon}
                                                color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                                                w={5}
                                                h={5}
                                            />
                                        </Box>
                                        <VStack align="start" spacing={2}>
                                            <Heading size="md">{advantage.title}</Heading>
                                            <Text>{advantage.description}</Text>
                                        </VStack>
                                    </HStack>
                                ))}
                            </SimpleGrid>
                        </VStack>
                    </Box>

                    {/* Section Contenu SEO */}
                    <Box
                        bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                        p={10}
                        borderRadius="2xl"
                        boxShadow="xl"
                        w="full"
                    >
                        <VStack spacing={12} align="start">
                            {/* Tendances 2024 */}
                            <VStack align="start" spacing={4} w="full">
                                <Heading as="h3" size="lg" color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}>
                                    Les tendances du voyage en 2024
                                </Heading>
                                <Text fontSize="lg">
                                    En 2024, les voyageurs recherchent de plus en plus des expériences authentiques et personnalisées. Very Good Trip s'adapte à ces nouvelles tendances en proposant des solutions de voyage innovantes qui répondent aux attentes des voyageurs modernes : tourisme durable, expériences locales authentiques, et flexibilité maximale dans l'organisation.
                                </Text>
                            </VStack>

                            <Divider />

                            {/* Impact de l'IA */}
                            <VStack align="start" spacing={4} w="full">
                                <Heading as="h3" size="lg" color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}>
                                    L'impact de l'intelligence artificielle sur les voyages
                                </Heading>
                                <Text fontSize="lg">
                                    Very Good Trip représente l'avenir de la planification de voyages grâce à son utilisation innovante de l'intelligence artificielle. Notre technologie analyse des millions de données pour créer des itinéraires parfaitement adaptés à chaque voyageur, révolutionnant ainsi l'industrie du voyage personnalisé.
                                </Text>
                            </VStack>

                            <Divider />

                            {/* Voyage responsable */}
                            <VStack align="start" spacing={4} w="full">
                                <Heading as="h3" size="lg" color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}>
                                    Voyager de manière responsable avec Very Good Trip
                                </Heading>
                                <Text fontSize="lg">
                                    Notre plateforme intègre des critères de durabilité dans la création de vos itinéraires. Nous privilégions les hébergements écologiques, les activités respectueuses de l'environnement et les expériences qui soutiennent les communautés locales.
                                </Text>
                            </VStack>

                            <Divider />

                            {/* Le futur des voyages */}
                            <VStack align="start" spacing={4} w="full">
                                <Heading as="h3" size="lg" color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}>
                                    Le futur des voyages avec Very Good Trip
                                </Heading>
                                <Text fontSize="lg">
                                    Very Good Trip continue d'innover pour offrir une expérience de voyage toujours plus personnalisée et enrichissante. Nos développements futurs incluront des fonctionnalités de réalité augmentée, des recommandations encore plus précises et une intégration complète avec les services de voyage locaux.
                                </Text>
                            </VStack>
                        </VStack>
                    </Box>

                    {/* CTA Final */}
                    <Box textAlign="center" py={10}>
                        <VStack spacing={6}>
                            <Heading size="xl">
                                Prêt à vivre une expérience de voyage unique ?
                            </Heading>
                            <Text fontSize="lg" maxW="2xl">
                                Rejoignez les milliers de voyageurs qui ont déjà transformé leurs rêves de voyage en réalité grâce à Very Good Trip.
                            </Text>
                            <Button
                                as={Link}
                                to="/"
                                size="lg"
                                bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                                _hover={{
                                    transform: 'translateY(-2px)',
                                    boxShadow: 'xl',
                                }}
                                px={8}
                                h={14}
                            >
                                Créer mon voyage personnalisé
                            </Button>
                        </VStack>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
};

export default IntroMain;