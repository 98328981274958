import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { AppProvider } from './context/AppContext';
import TravelHome from './components/pages/TravelHome';
import TravelPrograms from './components/pages/TravelPrograms';
import theme from './theme';
import TravelBlogGenerator from "./components/pages/TravelBlogGenerator";
import IntroducingVGT from "./components/pages/IntroducingVGT";
import PrivacyPolicy from "./components/pages/PrivacyPolicyPage";
import CarteDuSite from "./components/pages/CarteDuSite";
import MentionsLegales from "./components/pages/MentionsLegales";
import FAQ from "./components/pages/FAQ";
import Contact from "./components/pages/Contact";
import VoyagesSurMesure from "./components/pages/VoyagesSurMesure";
import SejoursHotels from "./components/pages/SejoursHotels";
import Excursions from "./components/pages/Excursions";
import ConseilsVoyage from "./components/pages/ConseilsVoyage";
import ScrollToTop from "./components/common/ScrollToTop";

function App() {
  return (
    <AppProvider>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <Router>
          <ScrollToTop/>
            <Routes>
              <Route path="/" element={<TravelHome/>}/>
              <Route path="/mes-voyages" element={<TravelPrograms/>}/>
              <Route path="/" element={<TravelHome />} />
              <Route path="/présentation" element={<IntroducingVGT />} />
              <Route path="/blog-generator" element={<TravelBlogGenerator />} />
              <Route path="/sejours-hotels" element={<SejoursHotels />} />
              <Route path="/voyages-sur-mesure" element={<VoyagesSurMesure />} />
              <Route path="/excursions" element={<Excursions />} />
              <Route path="/conseils-voyage" element={<ConseilsVoyage />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/carte-du-site" element={<CarteDuSite />} />
              <Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
              <Route path="/mentions-legales" element={<MentionsLegales />} />
            </Routes>
        </Router>
      </ChakraProvider>
    </AppProvider>
  );
}

export default App;
