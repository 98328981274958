import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    VStack,
    HStack,
    Icon,
    Button,
    Badge,
    Image,
    useColorMode,
    useTheme,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import {
    FaSwimmingPool,
    FaSpa,
    FaConciergeBell,
    FaWifi,
    FaStar,
    FaUmbrella,
} from 'react-icons/fa';

const HotelStays = () => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    const hotelCategories = [
        {
            title: "Hôtels de Luxe",
            description: "Une expérience exceptionnelle dans les plus beaux établissements",
            image: "./img/home/hotel-luxe.jpg",
            features: ["Service 5 étoiles", "Restaurants gastronomiques", "Spa de luxe", "Vues imprenables"],
            price: "À partir de 500€/nuit"
        },
        {
            title: "Resorts All-Inclusive",
            description: "Formule tout-inclus pour des vacances sans souci",
            image: "./img/home/all-inclusive.jpg",
            features: ["Repas inclus", "Activités illimitées", "Animation", "Plage privée"],
            price: "À partir de 300€/nuit"
        },
        {
            title: "Boutique Hôtels",
            description: "Des établissements uniques au charme authentique",
            image: "./img/home/shop-hotel.jpg",
            features: ["Design unique", "Atmosphère intimiste", "Service personnalisé", "Emplacement privilégié"],
            price: "À partir de 200€/nuit"
        }
    ];

    const amenities = [
        { icon: FaSwimmingPool, label: "Piscine" },
        { icon: FaSpa, label: "Spa" },
        { icon: FaWifi, label: "WiFi" },
        { icon: FaUmbrella, label: "Plage" },
        { icon: FaConciergeBell, label: "Concierge" },
    ];

    return (
        <Box
            minH="100vh"
            bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
            pt="70px"
        >
            {/* Hero Section */}
            <Box
                bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                py={20}
                position="relative"
                overflow="hidden"
            >
                <Container maxW="7xl">
                    <VStack spacing={6} align="center" textAlign="center">
                        <Badge
                            colorScheme="pink"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm"
                        >
                            Séjours Hôtels
                        </Badge>
                        <Heading size="3xl" maxW="800px">
                            Des hôtels d'exception pour des séjours inoubliables
                        </Heading>
                        <Text fontSize="xl" maxW="2xl" opacity={0.8}>
                            Découvrez notre sélection d'établissements de prestige aux quatre coins du monde
                        </Text>
                        <Button
                            as={RouterLink}
                            to="/"
                            size="lg"
                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                            _hover={{
                                bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg'
                            }}
                            mt={4}
                        >
                            Découvrir nos offres
                        </Button>
                    </VStack>
                </Container>
            </Box>

            {/* Amenities Section */}
            <Box pt={20} >
                <Container maxW="3xl" textAlign="center"
                    bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                    p={6} borderRadius="xl" shadow="lg">
                    <SimpleGrid columns={{ base: 2, md: 5 }} spacing={8}>
                        {amenities.map((amenity, index) => (
                            <VStack key={index} spacing={4}>
                                <Icon
                                    as={amenity.icon}
                                    w={10}
                                    h={10}
                                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                />
                                <Text fontWeight="medium">{amenity.label}</Text>
                            </VStack>
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Hotel Categories */}
            <Box py={20}>
                <Container maxW="7xl">
                    <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={10}>
                        {hotelCategories.map((category, index) => (
                            <Box
                                key={index}
                                bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                                borderRadius="2xl"
                                overflow="hidden"
                                boxShadow="xl"
                                transition="transform 0.3s"
                                _hover={{ transform: 'translateY(-5px)' }}
                            >
                                <Box h="200px" overflow="hidden">
                                    <Image
                                        src={category.image}
                                        alt={category.title}
                                        w="full"
                                        h="full"
                                        objectFit="cover"
                                        transition="transform 0.3s"
                                        _hover={{ transform: 'scale(1.1)' }}
                                    />
                                </Box>
                                <VStack p={6} spacing={4} align="stretch">
                                    <Heading size="lg">{category.title}</Heading>
                                    <Text opacity={0.8}>{category.description}</Text>
                                    <VStack align="stretch" spacing={2}>
                                        {category.features.map((feature, fIndex) => (
                                            <HStack key={fIndex}>
                                                <Icon as={FaStar} color="yellow.400" />
                                                <Text>{feature}</Text>
                                            </HStack>
                                        ))}
                                    </VStack>
                                    <Text
                                        fontWeight="bold"
                                        fontSize="lg"
                                        color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                    >
                                        {category.price}
                                    </Text>
                                    <Button
                                        as={RouterLink}
                                        to="/"
                                        variant="outline"
                                        borderColor={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        _hover={{
                                            bg: colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg,
                                            color: colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color
                                        }}
                                    >
                                        En savoir plus
                                    </Button>
                                </VStack>
                            </Box>
                        ))}
                    </SimpleGrid>
                </Container>
            </Box>

            {/* CTA Section */}
            <Box pb={20} >
                <Container maxW="3xl" textAlign="center"
                    bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                    p={20} borderRadius="xl" shadow="lg">
                    <VStack spacing={6}>
                        <Heading>Prêt à réserver votre séjour ?</Heading>
                        <Text fontSize="lg">
                            Notre équipe est à votre disposition pour vous aider à choisir l'établissement parfait
                            pour votre prochain séjour.
                        </Text>
                        <Button
                            as={RouterLink}
                            to="/"
                            size="lg"
                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                            _hover={{
                                bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg'
                            }}
                        >
                            Créer mon voyage                        </Button>
                    </VStack>
                </Container>
            </Box>
        </Box >
    );
};

export default HotelStays;