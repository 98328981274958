import React from 'react';
import {
  Box,
  Container,
  Text,
  VStack,
  HStack,
  Grid,
  GridItem,
  Link,
  Icon,
  Divider,
  useTheme,
  useColorMode,
} from '@chakra-ui/react';
import {
  FaInstagram,
  FaFacebook,
  FaTiktok,
  FaEnvelope,
  FaMapMarkerAlt,
//  FaPlane,
//  FaHotel,
//  FaUmbrella,
//  FaPassport
} from 'react-icons/fa';

const Footer = () => {
  const { colorMode } = useColorMode();
  const theme = useTheme();

//   const services = [
//    { name: "Voyages sur mesure", icon: FaPlane, link: "/voyages-sur-mesure" },
//    { name: "Séjours hôtels", icon: FaHotel, link: "/sejours-hotels" },
//    { name: "Excursions", icon: FaUmbrella, link: "/excursions" },
//    { name: "Conseils voyage", icon: FaPassport, link: "/conseils-voyage" }
//  ];

  const links = [
    { name: "À Propos", link: "/présentation" },
    { name: "FAQ", link: "/faq" },
    { name: "Contact", link: "/contact" },
    { name: "Politique de confidentialité", link: "/politique-de-confidentialite" },
    { name: "Mentions légales", link: "/mentions-legales" },
    { name: "Carte du site", link: "/carte-du-site" },
  ];

  const contacts = [
    /*{ icon: FaPhone, content: "+33 1 23 45 67 89", link: "tel:+33123456789" },*/
    { icon: FaEnvelope, content: "contact@verygoodtrip.com", link: "mailto:contact@verygoodtrip.com" },
    { icon: FaMapMarkerAlt, content: "Lyon, France", link: "#" }
  ];

  const socials = [
    { icon: FaInstagram, link: "#" },
    { icon: FaFacebook, link: "#" },
    { icon: FaTiktok, link: "#" }
  ];

  return (
    <Box
      as="footer"
      pt={12}
      pb={6}
      bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.lightgrey}
      color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
    >
      <Container maxW="6xl">
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
          gap={{ base: "8", lg: "12" }}
          mb={10}
        >
          {/* À propos */}
          <GridItem>
            <VStack align="start" spacing={4}>
              <Box
                bg={theme.colors.dark.bg}
                py={2}
                px={4}
                borderRadius="full"
                display="inline-block"
              >
                <Text fontWeight="bold" fontSize="lg" display="inline">
                  <Text as="span" color="white" display="inline">
                    Very Good{" "}
                  </Text>
                  <Text
                    as="span"
                    color={colorMode === 'dark'
                      ? theme.colors.dark_tertiary.bg
                      : theme.colors.light_tertiary.bg}
                    display="inline"
                  >
                    Trip
                  </Text>
                </Text>
              </Box>              <Text fontSize="sm" maxW="300px">
                Votre générateur de voyages personnalisés. Nous créons des expériences uniques adaptées à vos envies et à votre style de voyage.
              </Text>

              <HStack spacing={4}>
                {socials.map((social, index) => (
                  <Link
                    key={index}
                    href={social.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    _hover={{
                      color: colorMode === 'dark'
                        ? theme.colors.dark_tertiary.bg
                        : theme.colors.light_tertiary.bg
                    }}
                  >
                    <Icon as={social.icon} w={5} h={5} />
                  </Link>
                ))}
              </HStack>

            </VStack>
          </GridItem>

          {/* Services */}
{/* Bloc services cachés en attendant de trouver une solution de présentation
          <GridItem>
            <VStack align="start" spacing={4}>
              <Text fontWeight="bold" fontSize="lg">Nos Services</Text>
              {services.map((service, index) => (
                <Link
                  key={index}
                  href={service.link}
                  _hover={{
                    color: colorMode === 'dark'
                      ? theme.colors.dark_tertiary.bg
                      : theme.colors.light_tertiary.bg
                  }}
                  display="flex"
                  alignItems="center"
                >
                  <Icon
                    as={service.icon}
                    color={colorMode === 'dark'
                      ? theme.colors.dark_tertiary.bg
                      : theme.colors.light_tertiary.bg}
                    mr={2}
                  />
                  <Text fontSize="sm">{service.name}</Text>
                </Link>
              ))}
            </VStack>
          </GridItem>
*/}
          {/* Contact */}
          <GridItem>
            <VStack align="start" spacing={4}>
              <Text fontWeight="bold" fontSize="lg">Contact</Text>
              {contacts.map((contact, index) => (
                <Link
                  key={index}
                  href={contact.link}
                  _hover={{
                    color: colorMode === 'dark'
                      ? theme.colors.dark_tertiary.bg
                      : theme.colors.light_tertiary.bg
                  }}
                  display="flex"
                  alignItems="center"
                >
                  <Icon
                    as={contact.icon}
                    color={colorMode === 'dark'
                      ? theme.colors.dark_tertiary.bg
                      : theme.colors.light_tertiary.bg}
                    mr={2}
                  />
                  <Text fontSize="sm">{contact.content}</Text>
                </Link>
              ))}
            </VStack>
          </GridItem>

          {/* Liens rapides */}
          <GridItem>
            <VStack align="start" spacing={4}>
              <Text fontWeight="bold" fontSize="lg">Liens Utiles</Text>
              {links.map((link, index) => (
                <Link
                  key={index}
                  href={link.link}
                  _hover={{
                    color: colorMode === 'dark'
                      ? theme.colors.dark_tertiary.bg
                      : theme.colors.light_tertiary.bg
                  }}
                >
                  <Text fontSize="sm">{link.name}</Text>
                </Link>
              ))}
            </VStack>
          </GridItem>
        </Grid>

        <Divider
          borderColor={colorMode === 'dark'
            ? 'whiteAlpha.200'
            : 'blackAlpha.200'}
          my={6}
        />

        <Text fontSize="sm" textAlign="center">
          © {new Date().getFullYear()} Very Good Trip. Tous droits réservés.
        </Text>
      </Container>
    </Box>
  );
};

export default Footer;
