import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  useColorMode,
  useTheme,
  Text
} from '@chakra-ui/react';

const MultistepForms = ({ steps, onSubmit }) => {
  const { colorMode } = useColorMode();
  const theme = useTheme();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});

  if (!steps || steps.length === 0) {
    return <div>No steps provided</div>;
  }

  const currentStep = steps[step];

  const handleChange = (field, value) => {
    setFormData(prevData => ({ ...prevData, [field]: value }));
  };

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      onSubmit(formData);
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  return (
    <Box
      borderWidth="0"
      rounded="xl"
      shadow="xl"
      maxWidth="900px"
      mx="auto"
      overflow="hidden"
      bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
    >
      {/* Header avec le numéro de l'étape */}
      <Box
        py={4}
        px={8}
        bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
      >
        <Text
          fontSize="sm"
          fontWeight="bold"
          color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
        >
          ÉTAPE {step + 1} SUR {steps.length}
        </Text>
      </Box>

      {/* Contenu du formulaire */}
      <Box px={8} py={6}>
        {currentStep && React.cloneElement(currentStep, { formData, handleChange })}
      </Box>

      {/* Boutons de navigation */}
      <Flex
        px={6}
        py={6}
        bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.lightgrey}
        justify={step === 0 ? 'flex-end' : 'space-between'} // Alignement conditionnel        align="center"
        direction={{ base: 'column', md: 'row' }} // Empile les boutons en mobile
      >
        {/* Bouton "Précédent" uniquement si ce n'est pas la première étape */}
        {step > 0 && (
          <Button
            onClick={handleBack}
            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
            _hover={{
              bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
              color: colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color
            }}
            _disabled={{
              opacity: 0.6,
              cursor: 'not-allowed',
              _hover: {
                bg: colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg
              }
            }}
            size="lg"
            px={8}
            mb={{ base: 4, md: 0 }} // Ajoute un espace en bas sur mobile
            width={{ base: '100%', md: 'auto' }} // Pleine largeur en mobile
          >
            Précédent
          </Button>
        )}

        {/* Bouton "Suivant" */}
        <Button
          onClick={handleNext}
          bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
          color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
          _hover={{
            bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
            color: colorMode === 'dark' ? theme.colors.dark.color : theme.colors.light.color
          }}
          size="lg"
          px={8}
          width={{ base: '100%', md: 'auto' }} // Pleine largeur en mobile
        >
          {step === steps.length - 1 ? 'Générer mon voyage' : 'Suivant'}
        </Button>
      </Flex>

    </Box>
  );
};

export default MultistepForms;
