import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    Image,
    Stack,
    useColorMode,
    useTheme
} from "@chakra-ui/react";
import { ArrowDownIcon } from '@chakra-ui/icons';

function FormIncentive() {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    return (
        <Box
            position="relative"
            bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
            py={20}
            overflow="hidden"
        >
            {/* Background Design Element */}
            <Box
                position="absolute"
                top="0%"
                left="60%"
                width="50%"
                height="70%"
                borderRadius="full"
                bg={colorMode === 'dark'
                    ? `rgba(${hexToRgb(theme.colors.dark_tertiary.bg)}, 0.1)`
                    : `rgba(${hexToRgb(theme.colors.light_tertiary.bg)}, 0.1)`}
                filter="blur(60px)"
                zIndex="0"
            />

            <Container maxW="7xl" position="relative">
                <Stack
                    direction={{ base: 'column', lg: 'row' }}
                    spacing={{ base: 10, lg: 20 }}
                    align="center"
                    justify="space-between"
                >
                    {/* Content Section */}
                    <VStack
                        spacing={6}
                        align={{ base: 'center', lg: 'start' }}
                        textAlign={{ base: 'center', lg: 'left' }}
                        maxW="600px"
                    >
                        <Box
                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                            p={2}
                            px={4}
                            borderRadius="full"
                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                            fontSize="sm"
                            fontWeight="500"
                            alignSelf={{ base: 'center', lg: 'flex-start' }}
                        >
                            Votre voyage commence ici
                        </Box>

                        <Heading
                            as="h2"
                            size="2xl"
                            fontWeight="bold"
                            lineHeight="shorter"
                            letterSpacing="tight"
                        >
                            Créez votre voyage en quelques clics
                        </Heading>

                        <Text fontSize="xl" color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}>
                            Notre formulaire intelligent s'adapte à vos préférences. Plus besoin de passer des heures à planifier,
                            laissez-nous vous guider vers votre prochaine destination de rêve.
                        </Text>

                        <Stack spacing={4} pt={4}>
                            <Text fontSize="lg" display="flex" alignItems="center" gap={2}>
                                ✨ Sélectionnez vos dates
                            </Text>
                            <Text fontSize="lg" display="flex" alignItems="center" gap={2}>
                                ✨ Choisissez vos centres d'intérêt
                            </Text>
                            <Text fontSize="lg" display="flex" alignItems="center" gap={2}>
                                ✨ Recevez votre itinéraire personnalisé
                            </Text>
                        </Stack>

                        <Box
                            display="flex"
                            alignItems="center"
                            gap={2}
                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                        >
                            <Text>Commencez maintenant</Text>
                            <ArrowDownIcon animation="bounce 2s infinite" />
                        </Box>

                    </VStack>

                    {/* Image Section */}
                    <Box
                        display={{ base: "none", lg: "block" }}
                        position="relative"
                        w={{ base: "full", lg: "50%" }}
                        maxW="500px"
                        h="400px"
                    >
                        <Image
                            src="./img/home/home-travel-detoure.png"
                            alt="Travel Planning Illustration"
                            w="full"
                            h="full"
                            objectFit="contain"
                            filter={colorMode === 'dark' ? 'brightness(0.9)' : 'none'}
                            transition="transform 0.3s ease-in-out"
                            _hover={{ transform: 'scale(1.05)' }}
                        />
                    </Box>
                </Stack>
            </Container>

            <style>
                {`
                    @keyframes bounce {
                        0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
                        40% { transform: translateY(5px); }
                        60% { transform: translateY(3px); }
                    }
                `}
            </style>
        </Box>
    );
}

// Fonction utilitaire pour convertir les couleurs hex en rgb
const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
        : '0, 0, 0';
};

export default FormIncentive;