import { Grid, GridItem } from '@chakra-ui/react';
import HomeNavbar from '../common/HomeNavbar';
import Footer from '../common/Footer';
import MainContact from '../sections/MainContact';

export default function TestPage() {
    return (
        <Grid
            templateAreas={
                `"header header"
        "main main"
         "footer footer"` }
            gridTemplateRows={'auto 1fr 60px'}
            gridTemplateColumns='1fr'
            h='100vh'
            overflowX="hidden"
        >

            <GridItem area={'header'}>
                <HomeNavbar />
            </GridItem>

            <GridItem area={'main'}>
                <MainContact />
            </GridItem>

            <GridItem area={'footer'}>
                <Footer />
            </GridItem>

        </Grid>
    );
};
