import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    VStack,
    HStack,
    Icon,
    Button,
    Badge,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useColorMode,
    useTheme,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import {
    FaPassport,
    FaSuitcase,
    FaFirstAid
} from 'react-icons/fa';

const TravelTips = () => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    const travelAdvice = [
        {
            icon: FaPassport,
            title: "Documents de voyage",
            content: [
                "Passeport valide 6 mois après le retour",
                "Visa selon la destination",
                "Assurance voyage",
                "Copie des documents importants",
                "Certificats de vaccination si requis"
            ]
        },
        {
            icon: FaSuitcase,
            title: "Préparer sa valise",
            content: [
                "Liste de vêtements adaptés au climat",
                "Médicaments personnels",
                "Adaptateurs électriques",
                "Articles de toilette essentiels",
                "Appareils électroniques et chargeurs"
            ]
        },
        {
            icon: FaFirstAid,
            title: "Santé et sécurité",
            content: [
                "Vaccins recommandés",
                "Trousse de premiers soins",
                "Numéros d'urgence locaux",
                "Coordonnées ambassade",
                "Précautions alimentaires"
            ]
        }
    ];

    const faqQuestions = [
        {
            question: "Quand réserver mon voyage ?",
            answer: "Il est recommandé de réserver votre voyage au moins 3 à 6 mois à l'avance pour bénéficier des meilleurs tarifs et disponibilités, particulièrement pendant la haute saison."
        },
        {
            question: "Quelle assurance voyage choisir ?",
            answer: "Une bonne assurance voyage doit couvrir les frais médicaux, le rapatriement, l'annulation/interruption de voyage et la perte de bagages. Nous recommandons de comparer plusieurs offres pour trouver celle qui correspond le mieux à vos besoins."
        },
        {
            question: "Comment gérer le décalage horaire ?",
            answer: "Pour minimiser les effets du jet lag, essayez de vous adapter à l'heure locale dès votre arrivée, restez hydraté, évitez l'alcool pendant le vol et faites une courte sieste si nécessaire à l'arrivée."
        }
    ];

    return (
        <Box
            minH="100vh"
            bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_secondary.bg}
            pt="70px"
            color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
        >
            {/* Hero Section */}
            <Box
                bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
                color={colorMode === 'dark' ? theme.colors.dark_secondary.color : theme.colors.light_secondary.color}
                py={20}
            >
                <Container maxW="7xl">
                    <VStack spacing={6} align="center" textAlign="center">
                        <Badge
                            colorScheme="pink"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm"
                        >
                            Guide du voyageur
                        </Badge>
                        <Heading size="3xl">Conseils pour un voyage réussi</Heading>
                        <Text fontSize="xl" maxW="2xl" opacity={0.8}>
                            Découvrez nos conseils d'experts pour préparer et profiter au mieux de votre voyage
                        </Text>
                    </VStack>
                </Container>
            </Box>

            {/* Conseils principaux */}
            <Container maxW="7xl" py={16}>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                    {travelAdvice.map((advice, index) => (
                        <VStack
                            key={index}
                            p={8}
                            borderRadius="xl"
                            bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white} boxShadow="xl"
                            spacing={6}
                            align="start"
                        >
                            <Icon
                                as={advice.icon}
                                w={10}
                                h={10}
                                mx="auto"
                                color={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                            />
                            <Heading size="lg">{advice.title}</Heading>
                            <VStack align="start" spacing={3}>
                                {advice.content.map((item, i) => (
                                    <HStack key={i} spacing={3}>
                                        <Box
                                            w={2}
                                            h={2}
                                            borderRadius="full"
                                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                                        />
                                        <Text>{item}</Text>
                                    </HStack>
                                ))}
                            </VStack>
                        </VStack>
                    ))}
                </SimpleGrid>
            </Container>

            {/* FAQ Section */}
            <Box py={16}
                bg={colorMode === 'dark' ? theme.colors.dark_bg.darkgrey : theme.colors.light_bg.white}
            >
                <Container maxW="4xl">
                    <VStack spacing={8}>
                        <Heading textAlign="center">Questions fréquentes</Heading>
                        <Accordion allowMultiple width="100%">
                            {faqQuestions.map((faq, index) => (
                                <AccordionItem
                                    key={index}
                                    border="none"
                                    mb={4}
                                >
                                    <AccordionButton
                                        bg={colorMode === 'dark' ? theme.colors.dark_secondary.bg : theme.colors.light_bg.lightgrey} p={4}
                                        borderRadius="md"
                                        _hover={{
                                            bg: colorMode === 'dark' ? theme.colors.dark_bg.black : theme.colors.light_bg.middlegrey,
                                        }}
                                    >
                                        <Box flex="1" textAlign="left" fontWeight="500">
                                            {faq.question}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel pb={4}>
                                        {faq.answer}
                                    </AccordionPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </VStack>
                </Container>
            </Box>

            {/* CTA Section */}
            <Box py={20}>
                <Container maxW="3xl" textAlign="center">
                    <VStack spacing={6}>
                        <Heading>Besoin de conseils personnalisés ?</Heading>
                        <Text fontSize="lg">
                            Nos experts voyage sont là pour répondre à toutes vos questions et vous aider à préparer votre prochain voyage.
                        </Text>
                        <Button
                            as={RouterLink}
                            to="/"
                            size="lg"
                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                            _hover={{
                                bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg'
                            }}
                        >
                            Créer mon voyage                        </Button>
                    </VStack>
                </Container>
            </Box>
        </Box>
    );
};

export default TravelTips;